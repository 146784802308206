<div>
  <div>
    <div>
      <h3 class="mt-0 pt-0 pb-1 mb-0 titulo-mensagens" aria-label="Minha lista de Itens">Minha lista de Itens</h3>
      <p>Visualize aqui os itens adicionados, exclua ou baixe a sua lista no formato PDF, JSON, TXT ou CSV.</p>
      <hr>
      <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
        <div>
          <h6 class="m-0 p-0">Itens Adicionados <div class="br-tag count danger"><span>{{ itens.length }}</span>
            </div>
          </h6>
        </div>
        <div style="display: flex; flex-direction: row; align-items: center;">
          <div *ngIf="itensExcluidos.length > 0" style="margin-right: 5px;">
            <button class="br-button secondary small" type="button" (click)="desfazer()" aria-label="Desfazer">Desfazer</button>
          </div>
          <div *ngIf="itens.length > 0" style="margin-right: 5px;">
            <button class="br-button secondary small" type="button" (click)="clean.show()" aria-label="Apagar">Apagar todos</button>
          </div>
          <br-confirmation #clean popupTitle="Confirmação de exclusão" acceptLabel="Sim" rejectLabel="Não"
          question="Deseja realmente limpar o carrinho?" (accept)="limpar()">
        </br-confirmation>
          <p-slideMenu #menu [model]="items" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"
            appendTo="body" [popup]="true" (!mouseover)="menu.hide"></p-slideMenu>
          <button *ngIf="itens.length > 0" class="br-button primary small" type="button" aria-label="Exportar"
            (mouseover)="menu.toggle($event)">
            Exportar</button>
        </div>
      </div>
      <hr>
        <span style="font-size: 16px; color: #9f9a9a;">* Característica Inativa</span>
        <div class="row">
          <div class="col-12">
            <p-orderList [value]="itens" [dragdrop]="true" controlsPosition="right" (onReorder)="ordenar()">
              <ng-template pTemplate="header">
                <div class="flex-container" style="align-items:center;">
                  <div class="col-1">Tipo </div>
                  <div class="col-1">Código </div>
                  <div class="col-3">Nome </div>
                  <div class="col-2"><span pTooltip="Nomenclatura Comum do Mercosul (NCM)<br><br>Regulamentado pelo Decreto N<sup>o</sup> 11.890, de 22 de Janeiro de 2024" 
                    tooltipPosition="bottom" [escape]="false">NCM</span></div>
                  <div class="col-2"><span pTooltip="Nomenclatura Brasileira de Serviços, Intangíveis e Outras Operações que Produzam Variações no Patrimônio (NBS)<br><br>Instituida pelo Decreto N<sup>o</sup> 7.708, de 02 de Abril de 2012" 
                    tooltipPosition="bottom" [escape]="false">NBS</span></div>
                  <div class="col-2">Unidade Selecionada</div>
                  <div class="col-1" style="text-align: center;"> Ação </div>
                </div>
              </ng-template>
              <ng-template pTemplate="item" let-item>
                <div class="flex-container" style="align-items:center;">
                  <div class="col-1">
                    <div class="br-tag text support-10 m-0" *ngIf="item.tipo === 'SERVIÇO'">
                      <span style="font-size: 12px; ">S
                      </span>
                    </div>
                    <div class="br-tag text support-12 m-0" *ngIf="item.tipo === 'MATERIAL'">
                      <span style="font-size: 12px">M
                      </span>
                    </div>
                  </div>
                  <div class="col-1">{{item.id}}  <span *ngIf="item.itemSustentavel == true" class="fas fa-leaf small"  pTooltip="Sustentável"></span></div>
                  <div class="col-3">
                    <span *ngIf="item.tipo === 'MATERIAL'">
                      <td class="col-5"> <b>{{item.sequencial}} - {{item.carrinhoNome}}</b> <br>

                        <span  *ngFor="let categoria of item.carrinhoCaracteristicas?.split('|')">
                          <span *ngIf=" categoria.charAt(0) == '#' ">
                            <p class="pb-0 mb-0">
                             <span style="color: black;">{{categoria.substring(1, categoria.indexOf(":")+1)}}
                                {{categoria.substring(categoria.indexOf(":")+1)}}
                             </span>
                            </p>
                          </span>
                          <span *ngIf=" categoria.charAt(0) == '&' ">
                            <p class="pb-0 mb-0">
                               <span style="color: #9f9a9a;">{{categoria.substring(1, categoria.indexOf(":")+1)}}
                                  {{categoria.substring(categoria.indexOf(":")+1)}}
                                </span>
                            </p>
                          </span>
                        </span>
                      </td>
                    </span>
                    <div *ngIf="item.tipo === 'SERVIÇO'">
                      <td class="col-5"><strong>{{item.sequencial}} - {{item.nome | lowercase}}</strong></td>
                    </div>
                  </div>
                  <div class="col-2">{{item.codigoNcm | ncmPipe}}</div>
                  <div class="col-2">{{item.codigoNbs | nbsPipe}}</div>
                  <div class="col-2">{{item.unidade.nomeUnidadeMedida}}</div>
                  <div class="col-1" style="text-align: center;">
                    <button type="button" class="br-button secondary circle small" (click)="openModal1(item.id)" aria-label="ícone ilustrativo"><i
                        class="fas fa-trash-alt"></i></button>
                  </div>
                </div>

              </ng-template>
              <br-confirmation #confirmExclusao popupTitle="Confirmação de exclusão" acceptLabel="Sim" rejectLabel="Não"
                question="Deseja realmente excluir esse item?" (accept)="excluir(excluirId)">
              </br-confirmation>
            </p-orderList>

        </div>
      </div>
