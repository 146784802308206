import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BuscaService } from 'src/app/service/busca-service';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manter-catalogador',
  templateUrl: './manter-catalogador.component.html',
  styleUrls: ['./manter-catalogador.component.css']
})
export class ManterCatalogadorComponent implements OnInit {

  uasgs: any;

  visualizaCpfCatalogador: number;
  visualizaCatalogadorAtivo: boolean;
  visualizaNomeCatalogador: string;
  visualizaEmailCatalogador: string;
  visualizaUasgCatalogador: number;

  ativos: boolean = false;

  editaCatalogadorId: any;
  editaCpfCatalogador: number;
  editaCatalogadorAtivo: boolean;
  editaNomeCatalogador: string;
  editaEmailCatalogador: string;
  editaUasgCatalogador: number;

  disableSave: any;

  incluirCpf: any;
  incluirNome: any;
  incluirUasgCatalogador: any;
  incluirEmail: any;

  filtroCatalogador: any;

  escondeValor: any;
  listaCatalogadores: any[];
  catalogadores: any[];

  visible: boolean = false;
  expandCollapsed: boolean;
  inputBusca: string;
  modalRef1: BsModalRef<Object>;

  erroNome: any;
  erroCpf: any;
  
  @ViewChild('catalogadorSucessoEditar', {static: false} ) catalogadorSucessoEditar: any;
  @ViewChild('catalogadorSucessoIncluir', {static: false} ) catalogadorSucessoIncluir: any;

  constructor(private router:Router, private service:BuscaService, private messageService: MessageService, private modalService: BsModalService,) { }

  ngOnInit(): void {
    this.pesquisar();
  }

  voltarTelaInicial(): void {
    this.router.navigate(['/arealogada']);
  }


  pesquisar() {
    
    this.service.obterCatalogadores().subscribe(data=>{
      this.listaCatalogadores = data;
      this.catalogadores = data;

      this.somenteAtivos(); // somente ativos por default
    })

    this.service.obterUasgs().subscribe(data=>{
      this.uasgs = data.filter((u: { catalogador: boolean; }) => u.catalogador);
    })

  }

  enterPesquisar($event: KeyboardEvent) {
    if($event.key == "Enter"){
      this.pesquisar();
    }
  }

  filtrarCatalogador() {
    const regexp = new RegExp(this.filtroCatalogador, 'i');
    this.listaCatalogadores = this.catalogadores.filter(catalogador => {
    return ( (this.ativos ? catalogador.ativo : true) && (  regexp.test(catalogador.nomeCatalogador) || 
                                                            regexp.test(catalogador.codigoUasgCatalogador)
                                                        )
            )
      ;

    });
  }

  pegarDados(tipo:string, catalogador: any) {
    if (tipo === 'visualiza') {
      this.visualizaNomeCatalogador = catalogador.nomeCatalogador;
      this.visualizaCatalogadorAtivo = catalogador.ativo;
      this.visualizaEmailCatalogador = catalogador.emailCatalogador;
      this.visualizaCpfCatalogador = catalogador.cpfCatalogador;
      this.visualizaUasgCatalogador = catalogador.codigoUasgCatalogador;
    } else if (tipo === 'edita') {
      this.editaCatalogadorId = catalogador.codigoCatalogador;
      this.editaCpfCatalogador = catalogador.cpfCatalogador;
      this.editaNomeCatalogador = catalogador.nomeCatalogador;
      this.editaCatalogadorAtivo = catalogador.ativo;
      this.editaUasgCatalogador = catalogador.codigoUasgCatalogador;
      this.editaEmailCatalogador = catalogador.emailCatalogador;
    }
  }

  sairModalEditar() {
    this.modalRef1.hide();
    this.pesquisar();
  }

  somenteAtivos() {
    this.ativos = !this.ativos;
    if (this.ativos) {
      this.listaCatalogadores = this.listaCatalogadores.filter((catalogador) => catalogador.ativo);
    } else {
      this.listaCatalogadores = this.catalogadores;
    }
  }


  abrirCriar() {
    // Executa ao clicar no botão Incluir, que abre o modal para inserir novo Catalogador
    this.limpaCampos();
  }

  salvarNovoCatalogador() {
    // Executa ao clicar no botão de "salvar", no modal para inserir novo catalogador
    let novoCatalogador = {
      codigoUasgCatalogador: parseInt(this.incluirUasgCatalogador),
      cpfCatalogador: this.incluirCpf,
      emailCatalogador: this.incluirEmail,
      nomeCatalogador: this.incluirNome
    };
    console.log(novoCatalogador);
    this.visible = true;
    this.service.inserirCatalogador(novoCatalogador).subscribe( _ => {
          this.visible = false;
          this.modalRef1 = this.modalService.show(this.catalogadorSucessoIncluir);
        },
        erro => {
          this.visible = false;
          if (typeof(erro.error) === 'string'){
            this.messageService.add({
              severity: 'error',
              summary: 'Erro ao salvar os dados no servidor: ' + erro.error
            });
          }else{
            this.messageService.add({
              severity: 'error',
              summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
            });
          }
        });
  }

  limpaCampos() {
    this.incluirCpf='';
    this.incluirUasgCatalogador='';
    this.incluirNome='';
    this.incluirEmail='';
  }

  sairModalSalvar() {
    window.location.reload();
  }


  atualizarCatalogador() {
    
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.visible = true;
  
    let dadosNovosCatalogador = {
      nomeCatalogador:this.editaNomeCatalogador,
      emailCatalogador:this.editaEmailCatalogador,
      codigoUasgCatalogador:this.editaUasgCatalogador,
      ativo:this.editaCatalogadorAtivo
    }

    this.service.atualizarCatalogador(this.editaCatalogadorId,dadosNovosCatalogador).subscribe(_=>{
      this.visible=false,
      this.modalRef1 = this.modalService.show(this.catalogadorSucessoEditar, modalOptions);
    },
    erro=>{
      this.visible=false;
      if(typeof(erro.error) === 'string'){
        this.messageService.add({
          severity:'error',
          summary: 'Erro ao salvar os dados no servidor: ' + erro.error
        });
      }else{
            this.messageService.add({
              severity: 'error',
              summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
            });
          }
    });
  }

  editaAtivoToggle() {
    this.editaCatalogadorAtivo = !this.editaCatalogadorAtivo;
  }

  validaCpf(campo: any) {

    this.erroCpf = null;
    
    const cpf = this.incluirCpf;

    if (!cpf) {
      return;
    }

    if (cpf.length !== 11) {
      this.erroCpf = 'CPF deve ter 11 dígitos';
      this.disableSave = true;
      campo.focus();
      return;
    }

    this.catalogadores.forEach(catalogador => {
      if (catalogador.cpfCatalogador === cpf) {
        this.erroCpf = "CPF já cadastrado";
        this.disableSave = true;
        campo.focus();
        return;
      }
    });

  }

  validaNome(campo: HTMLInputElement) {
    this.erroNome = null;

    if (!this.incluirNome) {
      return;
    }

    const nome = this.incluirNome.trim();

    if (this.incluirNome.length > 0 && nome.length === 0) {
      this.erroNome = 'Nome não pode ser vazio';
      this.disableSave = true;
      campo.focus();
      return;
    }
  }

  validaNomeEditar(campo: HTMLInputElement) {
    this.erroNome = null;

    if (!this.editaNomeCatalogador) {
      return;
    }

    const nome = this.editaNomeCatalogador.trim();

    if (this.editaNomeCatalogador.length > 0 && nome.length === 0) {
      this.erroNome = 'Nome não pode ser vazio';
      this.disableSave = true;
      campo.focus();
      return;
    }
  }

}
