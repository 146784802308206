<app-cabecalho-interno></app-cabecalho-interno>
<br /><br /><br />
<div class="container-lg">
  <div class="mb-4 mt-2" ><!-- cabeçalho -->
    <div class="row">
        <div class="col-sm-10" id="cabecalho">
          <h2 class="mb-0" aria-label="Manter Material">
            <span class="mouseover">Catálogo - Incluir serviços</span>
          </h2>
          <h6 style="font-weight: 400;" class="mb-0 mt-1"> Use esta área para criar um serviço</h6>
        </div>
        <div class="col-sm-2 mt-4 text-right" >
        </div>
      </div>
  </div>
  <div  class=" card border-0">
    <div class="row ">
      <div class="col-sm-5  card border-0"></div>
      <div class="col-sm-3 card border-0">
        <button class="br-button is-secondary"  type="button" (click)="expandeOculta()">Expandir/Retrair</button>
      </div>
      <div class="col-sm-2 card border-0">
        <button class="br-button is-secondary"  type="button" (click)="voltarTelaInicial()">Voltar</button>
      </div>
      <div class="col-sm-2 card border-0">
        <br-loading [visible]="visible" label="Salvando ..."></br-loading>
        <button class="br-button is-primary" type="button" (click)="confirmCont.show()" >Salvar </button>
      </div>
    </div>

      <br-confirmation  #confirmCont popupTitle="Confirme a inclusão" acceptLabel="Sim" rejectLabel="Não"
                       question="Deseja realmente incluir este serviço?" (accept)="validaCampos()">
      </br-confirmation>

      <br-confirmation #ServiceSucess popupTitle="Serviço criado com sucesso!" acceptLabel="Novo Serviço" rejectLabel="Voltar"
                       question="Código do serviço: {{codigoServico}}" (accept)="zerarTudo()" (reject)="voltarTelaInicial()">
      </br-confirmation>
  </div>
    <br>
    <hr/>
  <div class="servico-form" [formGroup]="formulario">
    <br-fieldset [collapsible]="true" fieldsetTitle="DADOS BÁSICOS" [collapsed]="expandCollapsed">
      <div>
        <div class="row">
          <div class="col-6">
            <label>CPC</label><label class="asterisco">*</label><label>{{ valorCpc }}</label>
          </div>
          <div class="d-flex flex-row justify-content-end col-6">
            <!--
            <div class="br-switch medium switch-basic">
              <input id="suspenso" type="checkbox" formControlName="suspenso" />
              <label for="suspenso">Suspenso</label>
            </div>
            -->
            <div class="br-switch medium switch-basic" style="margin-left: 10px;">
              <input
                id="amp"
                type="checkbox"
                formControlName="aplica_margemp"
                [attr.disabled]="formulario.value.codigoNbs.length==0 ? true : null"
              />
              <label for="amp">Aplica margem de preferência</label>
            </div>
            <div class="br-switch medium switch-basic" style="margin-left: 10px;">
              <input
                id="ecc"
                type="checkbox"
                formControlName="exclusivo_central"
              />
              <label for="ecc">Exclusivo central de compras</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="br-input col-10">
            <input type="text" class="p-inputtext" value="{{ nomeCpc }}" placeholder="Escolha o CPC para o serviço" readonly/>
          </div>
          <div class="d-flex flex-row justify-content-end" style="margin-block: auto">
            <button class="br-button secondary col-11" type="button" data-toggle="modal" data-target="#templateSelecionarCpc">
              Buscar CPC
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-10 br-input">
          <label>Nome do serviço</label><label class="asterisco">*</label><label class="texto-direita" style="margin-top: 15px;">Caracteres restantes: <span>{{numMaxNome}}</span></label>
          <input type="text" class="p-inputtext" name="nome" #nome formControlName="nome" maxlength="120" required placeholder="Digite o nome do serviço"
             (keyup)="pressEnterNome($event, nome.value)" (blur)="trimTextOnBlur(nome)"/>
        </div>

        <div class="select-servicos col-2">
          <label>Tipo</label><label class="asterisco">*</label>
          <select class="custom-select select-input" formControlName="tipo">
            <option *ngFor="let t of tipo" [value]="t">{{ t }}</option>
          </select>
        </div>
      </div>

      <div class="row">

        <div class="col-4 br-input">
          <label>Código NBS</label>
          <p-inputMask mask="1.9999?.99.99"
            name="codigoNbs" #codigoNbs
            formControlName="codigoNbs"
            placeholder="Código NBS do serviço"
            (change)= "formulario.value.codigoNbs.length== 0 ? this.formulario.get('aplica_margemp')?.setValue(false) : null"
          ></p-inputMask>
        </div>

        <div class="col-8 br-input">
          <label>Descrição NBS</label>
          <input type="text" class="p-inputtext" name="descricaoNbs" #descricaoNbs formControlName="descricaoNbs" placeholder="Descrição NBS do serviço" maxlength="500"/>
        </div>

      </div>

      <div class="br-textarea large" style="margin-top: 10px">
        <label for="textarea-id1">Descrição</label><label class="texto-direita">Caracteres restantes: <span>{{numMaxDescricao}}</span></label>
        <textarea class="Area" #detalhes name="detalhes" formControlName="detalhes" rows="5" cols="2" maxlength="750"
        placeholder="Digite uma descrição para o serviço" (blur)="trimTextOnBlur(detalhes)" (keyup)="pressEnterDescricao($event, detalhes.value)"></textarea>
      </div>
    </br-fieldset>
    <br/>

    <hr/>
    <br-fieldset [collapsible]="true" fieldsetTitle="ALIAS / PALAVRA-CHAVE" [collapsed]="expandCollapsed">
      <div class="row">
        <label class="col-7">Alias ou palavra-chave</label>
        <label class="col-3" style="text-align: end;">Caracteres restantes: <span>{{numMaxAlias}}</span></label>
        <div class="col-2"></div>
      </div>

      <div class="row" style="margin-bottom: 15px;">
        <div class="br-input col-sm-10  input-alias">
          <input  type="text" class="p-inputtext" #alias formControlName="aliasTela" name="alias" placeholder="Digite o alias / palavra-Chave"
                  (keyup)="pressEnterAlias($event, alias.value)" maxlength="25"/>
        </div>
        <button class="br-button mx-auto secondary" type="button" (click)="adicionaListAlias(alias.value)">
          Incluir
        </button>
      </div>
      <div *ngFor="let alias of aliasArray" class="row">
        <div class="col-sm-1"></div>
        <label class="col-sm-6">{{ alias }}</label>
        <p-confirmDialog header="Confirmar exclusão" icon="far fa-question-circle" key="{{alias}}"></p-confirmDialog>
        <button class="br-button col-sm-2" type="button" (click)="removeListAlias(alias)">
          Excluir
        </button>
      </div>
    </br-fieldset>

    <hr>
    <br-fieldset [collapsible]="true" fieldsetTitle="NATUREZA DA DESPESA " [collapsed]="expandCollapsed">
      <div>
        <label>Natureza da despesa</label><label class="asterisco">*</label>
      </div>
      <div class="row" style="margin-bottom: 15px;">
        <div class="br-input col-sm-10 input-natureza">
          <p-inputMask mask="999999/99" placeholder="Digite uma natureza da despesa" #naturezaDespesa formControlName="naturezaTela" (keyup)="pressEnterNatureza($event, naturezaDespesa.value)">
          </p-inputMask>
        </div>
        <button class="br-button mx-auto secondary " type="button" (click)="adicionaListNatureza(naturezaDespesa.value)">
          Incluir
        </button>
      </div>
      <div *ngFor="let natureza of naturezaDespesaArray" class="row">
        <div class="col-sm-1"></div>
        <label class="col-sm-6">{{ natureza }}</label>
        <p-confirmDialog header="Confirmar exclusão" icon="far fa-question-circle" key="{{natureza}}"></p-confirmDialog>
        <button class="br-button col-sm-2" type="button" (click)="removeListNatureza(natureza)">
          Excluir
        </button>
      </div>
    </br-fieldset>

    <hr/>

    <br-fieldset [collapsible]="true" fieldsetTitle="UNIDADES DE MEDIDA" [collapsed]="expandCollapsed">
      <div>
        <label>Unidades de medida</label><label class="asterisco">*</label>
      </div>
      <div class="row" style="margin-bottom: 15px;">
        <select class="custom-select select-input col-sm-10" #unidadeMedidaServico formControlName="unidadeTela">
          <option value=""></option>
          <option *ngFor="let unidade of unidadeMedida" [value]="unidade.siglaUnidadeMedida">
            {{ unidade.nomeUnidadeMedida }}
          </option>
        </select>
        <button class="br-button mx-auto secondary" type="button" (click)="adicionaListUnidade(unidadeMedidaServico.value)">
          Incluir
        </button>
      </div>
      <div *ngFor="let unidades of unidadeMedidaArray" class="row">
        <div class="col-sm-1"></div>
        <label class="col-sm-6">{{ unidades.nomeUnidadeMedida }}</label>
        <p-confirmDialog header="Confirmar exclusão" icon="far fa-question-circle" key="{{unidades.nomeUnidadeMedida}}"></p-confirmDialog>
        <button class="br-button col-sm-2 " type="button" (click)="removeListUnidadeMedida(unidades)">
          Excluir
        </button>
      </div>
    </br-fieldset>

    <hr/>

    <br-fieldset [collapsible]="true" fieldsetTitle="Agrupamento" [collapsed]="expandCollapsed">
      <div class="row" style="margin-bottom: 15px;">
        <select class="custom-select select-input col-4" (change)="onChange($event)" #agrupamentoServico formControlName="agrupamentoTela">
          <option value=""></option>
          <option *ngFor="let item of agrupamento" [value]="item.codigoAgrupamentoServico">
            {{ item.nomeAgrupamentoServico }}
          </option>
        </select>
      </div>
    </br-fieldset>
    <div class="footer">

    </div>
  </div>

  <hr/>

  <div>
    <app-selecionar-cpc (cpcSelecionadoEvent)="selectCPC($event)"></app-selecionar-cpc>
  </div>
</div>
