import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nbsPipe'
})
export class NbsPipe implements PipeTransform {
  transform(value: string): string | null {
    if (!value || !/^\d{3,9}$/.test(value)) {
      return null;
    }

    const parts = [
      value.slice(0, 1),
      value.slice(1, 5),
      value.slice(5, 7),
      value.slice(7, 9)
    ];

    return parts.filter(part => part).join('.');
  }
}