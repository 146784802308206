export class Item {
  id: number;
  nome: string;
  descricao: string;
  unidade: any;
  tipo: any;
  codigoIdentificacaoBasica: any;
  type: any;
  unidadeFornecimento: any;
  nomeUnidadeFornecimento: any;
  siglaUnidadeFornecimento: any;
  capacidadeUnidadeFornecimento: any;
  nomeUnidadeMedida: any;
  siglaUnidadeMedida: any;
  codigo: any;
  descricaoDetalhada: any;
  sustentavel: any;
  qtdeItens: any;
  carrinhoNome: any;
  carrinhoCaracteristicas: any;
  codigoNcm?:string;
  codigoNbs?:string;
  aplicaMargemPreferencia?:boolean;
}
