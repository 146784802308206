import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NcmPipe } from './utils/ncm.pipe';
import { NbsPipe } from './utils/nbs.pipe';



@NgModule({
  declarations: [
    NcmPipe,
    NbsPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [NcmPipe, NbsPipe]
})
export class SharedModule {  }
