import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ncmPipe'
})
export class NcmPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {

    if(!value){
      return value;
    }

    if (value.length <= 4){ // XX.XX
      return value.replace(/(\d{2})(\d)/, "$1.$2");
    }

    //Mascara padrão: XXXX.XX.XX
    return value
      .replace(/\D/g, "")
      .replace(/(\d{4})(\d{2})(\d{2})/, "$1.$2.$3")
      .replace(/(\d{4})(\d{2})(\d)/, "$1.$2.$3")
      .replace(/(\d{4})(\d{2})/, "$1.$2")
      .replace(/(\d{4})(\d)/, "$1.$2")
      ;
  }

}
