<div><!-- Carregar Conteúdo Principal aqui -->
  <div class="container-lg container mt-3" >
    <div class="row">
      <div class="col-md-4 col-sm-12 card" style="margin-left: -4px; margin-right: 4px;" >
        <div class="br-tag text support-10 m-0" style="width: 80px;" >
          <span style="font-size: 12px">SERVIÇO
          </span>
        </div>
        <div><h6 class="text-uppercase mb-2" style="font-size: 12px;"> Unidade de Fornecimento</h6> </div>
        <hr>

        <div>
          <span>Selecione uma unidade de fornecimento</span>
          <select [(ngModel)]="selected" class="form-control" (change)="escolherUnidadeMedida(selected)">
            <option *ngFor="let unidade of unidMedida" [ngValue]="unidade" style="font-size: 12px;">
              {{ unidade.nomeUnidadeMedida }}
            </option>
          </select>
        </div>
        <hr>
        <div><h6 class="text-uppercase mb-2" style="font-size: 12px;"> Natureza da Despesa</h6></div>

        <div *ngFor="let classcont of classificaContabil" class="br-radio"
             style="text-align: right; padding-right: 50px; line-height: 1.0;">
          <div class="row">
            <div class="col">
              {{classcont.codigoClassificacaoContabil}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-sm-12 card" style="margin-left: 4px; margin-right: -4px;">
        <div class="row d-flex justify-content-end">
          <div class="mx-2">
            <button *ngIf="isUsuarioGovernoLogado && usrProfile==='SOLICITANTE'" class="br-button is-primary small" type="button" (click)="abrirEfetuarPedido()">
              Solicitar pedido
            </button>
          </div>
          <div>
              <button type="button" class="br-button secondary small" (click)="voltar()" aria-label="Voltar">Voltar</button>
          </div>
        </div>

        <h6 style="font-size: 14px;">Item de serviço  </h6>
        <table>
          <tr class="row">
            <th class="col-2">Código</th>
            <th class="col-5">Nome do Serviço</th>
            <th class="col-2 cellwithcomment">NBS
              <div class="p-tooltip">
                <div class="p-tooltip-text">
                Nomenclatura Brasileira de Serviços, Intangíveis e Outras Operações que Produzam Variações
                no Patrimônio (NBS)<br><br>Instituida pelo Decreto N<sup>o</sup> 7.708, de 02 de Abril de 2012</div>
              </div>
            </th>
            <th class="col-3">Ação</th>
          </tr>
          <tr class="row">
            <td class="col-2"> {{idServico}} </td>
            <td class="col-5">  {{nomeServico}}</td>
            <td style="overflow-wrap: normal;" class="col-2">
              <span style="color: black">
                {{ codigoNbs | nbsPipe }}
              </span>
            </td>
            <td class="col-3">
              <button type="button" class="br-button secondary  small "   (click)="escolher()" aria-label="Adicionar">
                Adicionar
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>


