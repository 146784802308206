
<header class="br-header compact ">
  <div class="container-lg">
    <div class="header-top">
       <div class="header-logo">
      </div>
      <div class="header-actions mt-0" >
        <div class="header-links dropdown">
          <button class="br-button circle small" type="button" data-toggle="dropdown" aria-label="Abrir Acesso Rápido">
            <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
          </button>
          <div class="br-list">
            <a class="br-item" target="_blank" href="https://www.gov.br/pt-br/orgaos-do-governo">Órgãos do Governo</a>
            <a class="br-item" target="_blank" href="https://www.gov.br/acessoainformacao/pt-br">Legislação</a>
            <a class="br-item" target="_blank" href="https://www.gov.br/governodigital/pt-br/acessibilidade-digital">Acessibilidade</a>
          </div>
        </div>

        <div class="header-login">
          <div class="header-sign-in">
            <ng-container *ngTemplate="estimateTemplate">
           </ng-container>
          </div>
          <div class="header-avatar d-none">
            <div class="avatar dropdown"><span class="br-avatar" title="Fulana da Silva"><span class="image"><img src="https://picsum.photos/id/823/400" alt="Avatar"/></span></span>
              <button class="br-button circle small" type="button" aria-label="Abrir Menu de usuário" data-toggle="dropdown"><i class="fas fa-angle-down" aria-hidden="true"></i>
              </button>
              <div class="br-notification show">
                <div class="notification-header">
                  <div class="row">
                    <div class="col-10"><span class="text-bold">Fulano da Silva</span><br/><small><a>nome.sobrenome@dominio.gov</a></small></div>
                  </div>
                </div>
                <div class="notification-body">
                  <div class="br-tab">
                    <nav class="tab-nav">
                      <ul>
                        <li class="tab-item">
                          <button type="button" data-panel="notif-item298" aria-label="notif-item298"><span class="name"><span class="icon"><i class="fas fa-image" aria-hidden="true"></i></span><span class="name">Item</span></span></button>
                        </li>
                        <li class="tab-item is-active">
                          <button type="button" data-panel="notif-item299" aria-label="notif-item299"><span class="name"><span class="icon"><i class="fas fa-image" aria-hidden="true"></i></span><span class="name">Item</span></span></button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-bottom">
      <div class="header-menu">
        <div class="header-info" routerLink="/" >
          <div class="header-title">
            <img src="assets/imagem/logo-compras.svg" height="45px">
          </div>
          <div class="header-subtitle" style="display: none;">&nbsp;</div>
        </div>
      </div>
      <div class="header-search">
        <div class="br-input has-icon">
          <label for="searchbox-8443">Texto da pesquisa</label>
          <input class="has-icon" id="searchbox-8443" type="text" placeholder="O que você procura?"/>
          <button class="br-button circle small" type="button" aria-label="Pesquisar"><i class="fas fa-search" aria-hidden="true"></i>
          </button>
        </div>
        <button class="br-button circle search-close ml-1" type="button" aria-label="Fechar Busca" data-dismiss="searchHint"><i class="fas fa-times" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</header>
