<app-cabecalho-interno></app-cabecalho-interno>
<br /><br /><br />
<div class="container-lg">
  <div class="mb-4 mt-2" ><!-- cabeçalho -->
    <div class="row">
        <div class="col-8" id="cabecalho">
          <h2 class="mb-0" aria-label="visualizar servico">
            <span class="mouseover">Catálogo - Visualizar serviços</span>
          </h2>
          <h6 style="font-weight: 400;" class="mb-0 mt-1"> Use esta área para visualizar um serviço</h6>
        </div>
        <div class="col-4 row text-right" style="align-content: end">
          <div class="col-6 card border-0">
            <button class="br-button is-secondary"  type="button" (click)="voltarTelaInicial()">Voltar</button>
          </div>
          <div class="col-6 card border-0">
            <button class="br-button is-primary" type="button" (click)="editarServico()" >Editar</button>
          </div>
        </div>
      </div>
  </div>
  <hr>

  <div class="mb-4 row" *ngIf="dadosBasicos" >
    <h5 class="col-5">
      Código do serviço:
      <span style="color: red">{{ servicoParams.id }}</span>
    </h5>
    <div class="col-7 row">
      <div class="row col-4" style="justify-content: flex-end">
        <label for="ativo">Aplica margem de preferência:
          <span style="font-weight: bold" *ngIf="dadosBasicos.aplicaMargemPreferencia == true">Sim</span>
          <span style="font-weight: bold" *ngIf="dadosBasicos.aplicaMargemPreferencia == false">Não</span>
        </label>
    </div>
      <div class="col-4" style="justify-content: flex-end">
        <label for="ativo">Exclusivo central compras:
          <span style="font-weight: bold" *ngIf="dadosBasicos.servicoExclusivoCentralCompras == true">Sim</span>
          <span style="font-weight: bold" *ngIf="dadosBasicos.servicoExclusivoCentralCompras == false">Não</span>
        </label>
      </div>
      <div class="row col-2" style="justify-content: flex-end">
        <label for="ativo">Suspenso:
          <span style="font-weight: bold" *ngIf="dadosBasicos.servicoSuspenso == true">Sim</span>
          <span style="font-weight: bold" *ngIf="dadosBasicos.servicoSuspenso == false">Não</span>
        </label>
      </div>
      <div class="row col-2" style="justify-content: flex-end">
          <label for="ativo">Ativo:
            <span style="font-weight: bold" *ngIf="dadosBasicos.statusServico == true">Sim</span>
            <span style="font-weight: bold" *ngIf="dadosBasicos.statusServico == false">Não</span>
          </label>
      </div>
    </div>
  </div>
  <br-fieldset
  *ngIf="dadosBasicos"
    [collapsible]="true"
    fieldsetTitle="Dados base"
    [collapsed]="expandCollapsed"
  >
    <div class="br-input col-12 mb-2">
      <label> Nome:</label>
      <input
        class="p-inputtext"
        type="text"
        name="Nome"
        [(ngModel)]="dadosBasicos.descricaoServicoAcentuado"
        readonly
      />
    </div>
    <div class="row col-12 mb-2" style="padding-right: 0px;">
      <div class="br-input col-7">
        <label> Agrupamento:</label>
        <input
          class="p-inputtext"
          type="text"
          name="Agrupamento"
          placeholder="Agrupamento"
          [(ngModel)]="dadosBasicos.nomeAgrupamento"
          readonly
        />
      </div>
      <div class="br-input col-5" style="padding-right: 0px;">
        <label> Tipo:</label>
        <input
          *ngIf="dadosBasicos.tipoServico == 1"
          class="p-inputtext"
          type="text"
          name="Tipo"
          value="1 - Serviço de obras e engenharia"
          readonly
        />
        <input
          *ngIf="dadosBasicos.tipoServico == 2"
          class="p-inputtext"
          type="text"
          name="Tipo"
          value="2 - Outros serviços"
          readonly
        />
      </div>
    </div>
    <div class="br-input col-12 mb-2">
      <label> CPC: {{dadosBasicos.codigoCpc}}</label>
      <input
        class="p-inputtext"
        type="text"
        name="CPC"
        [(ngModel)]="dadosBasicos.nomeCpc"
        readonly
      />
    </div>
    <div class="row">

      <div class="col-4 br-input">
        <label>Código NBS</label>
        <p-inputMask mask="9.99?99.99.99" [readonly]="true"
          [(ngModel)]="dadosBasicos.codigoNbs"
          placeholder="Código NBS do serviço"
        ></p-inputMask>
      </div>

      <div class="col-8 br-input">
        <label>Descrição NBS</label>
        <input readonly type="text" class="p-inputtext" [(ngModel)]="dadosBasicos.descricaoNbs" placeholder="Descrição NBS do serviço" maxlength="500"/>
      </div>

    </div>
    <div class="br-textarea large col-12">
      <label for="textarea-id1">Descrição:</label>
      <textarea class="Area" rows="5" cols="2" maxlength="500" [(ngModel)]="dadosBasicos.descricaoServico"
      placeholder="Sem descrição do serviço"
      readonly></textarea>
    </div>
  </br-fieldset>
  <br-second-tab-set *ngIf="dadosBasicos">
    <br-second-tab
      name="Alias / Palavra-chave"
      [active]="true"
    >
      <p-table
        class="col-6"
        [value]="dadosBasicos.aliases"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="col-12" style="text-align: center;">
              Nome
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td class="col-12" style="text-align: center;">
              {{item.nomeAliasServico}}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </br-second-tab>
    <br-second-tab
      name="Natureza de despesa"
      [active]="false"
    >
      <p-table
        class="col-6"
        [value]="dadosBasicos.naturezas"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="col-3"></th>
            <th class="col-4">
              Natureza da despesa
            </th>
            <th class="col-3">
              Ativo
            </th>
            <th class="col-1"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td class="col-3"></td>
            <td class="col-4">
              {{item.codigoClassificacaoContabil}}
            </td>
            <td *ngIf="item.statusClassificacaoContabil == true" class="col-3">
              Sim
            </td>
            <td *ngIf="item.statusClassificacaoContabil == false" class="col-3">
              Não
            </td>
            <td class="col-1"></td>
          </tr>
        </ng-template>
      </p-table>
    </br-second-tab>
    <br-second-tab
      name="Unidades de medida"
      [active]="false"
    >
      <p-table
        class="col-6"
        [value]="dadosBasicos.unidadesDeMedidas"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="col-2"></th>
            <th class="col-3">
              Sigla
            </th>
            <th class="col-4">
              Nome
            </th>
            <th class="col-2">
              Ativo
            </th>
            <th class="col-1"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td class="col-2"></td>
            <td class="col-3">
              {{item.siglaUnidadeMedida}}
            </td>
            <td class="col-4">
              {{item.nomeUnidadeMedida}}
            </td>
            <td *ngIf="item.statusUnidadeMedida == true" class="col-2">
              Sim
            </td>
            <td *ngIf="item.statusUnidadeMedida == false" class="col-2">
              Não
            </td>
            <td class="col-1"></td>
          </tr>
        </ng-template>
      </p-table>
    </br-second-tab>
  </br-second-tab-set>
  <div style="margin-bottom: 100px;">

  </div>
</div>
