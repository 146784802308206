<div><!-- Carregar Conteúdo Principal aqui -->
  <div class="mb-4 mt-4"><!-- cabeçalho -->
    <div class="container-lg"><!-- container-lg -->
      <div class="row">
        <div class="col-12">
          <img src="assets/imagem/logo-compras.svg" height="32px">
        </div>
        <div class="col-md-12">
          <h2 class="ajuste mb-0" aria-label="Catálogo - Item Unitário" (click)="voltaTelaInicial()"><span class="mouseover">Catálogo</span></h2>
          <h6 style="font-weight: 400;" class="mb-0 mt-1">Item Unitário</h6>
        </div>
      </div>
      <div class="row text-right">
        <div class="col-12">
          <ng-container *ngIf="app.counter > app.counter_blink && app.counter > 0">
            <div class="col-12">
              <div class="br-tag text support-01">
                <i class="fas fa-clock" aria-hidden="true"></i><span>{{app.counter | relogio2}}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="app.counter <= app.counter_blink && app.counter > 0">
            <div class="col-12 blink_me">
              <div class="br-tag text support-10"><i class="fas fa-clock" aria-hidden="true"></i><span>{{app.counter | relogio2}}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="app.counter <= 0">
            <div class="col-12">
              <div class="br-tag text support-01" style="background-color: #fd7e14;">
                <i class="fas fa-clock"  aria-hidden="true"></i><span>00:00:00</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>


      <div class="row pesquisa mt-3 " ><!--<div class="col-3 "><p-dropdown [options]="filtros" [(ngModel)]="filtro1" optionLabel="name"></p-dropdown></div>-->
        <div class="col-12">
          <div class="br-input medium">
            <p-autoComplete #hint [(ngModel)]="textoBusca" [suggestions]="results" (completeMethod)="searchHint($event)"
                            (keyup)="liberarPesquisa($event)"  (keyup.enter)="pesquisarEnter($event)"
                            placeholder="Digite aqui o material ou serviço a ser pesquisado"
                            scrollHeight="500px" field="nome" [disabled]="pesquisando"
            >
              <ng-template let-item pTemplate="item">
                <div (click)="buscaEscolhido(item.codigo, item.tipo, item.nome)" data-disabled="pesquisando">
                  {{item.tipo}} - {{item.nome}}
                </div>
              </ng-template>
            </p-autoComplete>
            <button (click)="pesquisarLupa()" [disabled]="pesquisando" style="position: absolute; z-index: 1; margin-left: -40px; margin-top: 8px;" class="br-button circle small" type="button" aria-label="Ícone ilustrativo">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div><!-- container-lg -->
  </div><!-- cabeçalho -->

  <!---------------- novo container----------->
  <div *ngIf="tela1">
    <div class="container-lg container"  *ngIf="servicos?.length || materiais?.length">
      <p-tabView [(activeIndex)]="indexpainel" *ngIf="servicos?.length > 0  || materiais?.length > 0">
        <!-----inicio painel do serviçco ----->
        <p-tabPanel header="Serviços ({{servicos?.length}})" *ngIf="servicos?.length > 0"  ><!--<p-splitter  [panelSizes]="[30,70]" > --><!--<ng-template pTemplate >-->
          <div class="row">
            <div class="col-3 pesquisa-resultado " style="margin-left: -4px; margin-right: 4px;">
              <div class="  ">
                <div><h6 class="mb-1">Filtros</h6></div>
                <div><h6 class="text-uppercase mb-2" style="font-size: 12px;"> Grupos de Serviços</h6></div>
                <hr>
                <div class="pt-3">
                  <p-listbox [listStyle]="{'max-height':'650px','max-width':'300px'}"  [options]="gruposServico" [(ngModel)]="selectedGrupo" optionLabel="name"
                             (onClick)="grupoEscolhido($event)">
                    <ng-template let-itens pTemplate="item">
                      <div *ngIf="itens.name == 'TODOS OS GRUPOS'"><STRONG>{{itens.name}}</STRONG></div>
                      <div *ngIf="itens.name != 'TODOS OS GRUPOS'">{{itens.name}}</div>
                    </ng-template>
                  </p-listbox>
                </div>
              </div>
              <div>
              </div>
            </div><!--</ng-template> --><!-- <ng-template pTemplate class="card">-->
            <div class="col-9 pesquisa-resultado" style="margin-left: 4px; margin-right: -4px;" *ngIf="servicos?.length > 0">
              <div style="display: flex; flex-direction: row; align-items: center;">
                <h6 style="font-size: 14px;">Foram encontrados {{ servicos?.length }} resultados para os filtros </h6><!--<div class="br-tag interaction-select selected small"><label for="tag01"><span>{{ texto }}</span> </label></div>-->
                <div class="br-tag interaction-select small" *ngIf="selectedGrupo.name">
                  <span class="texto-overflow">{{ selectedGrupo.name }}</span>
                  <i class="fas fa-times" aria-hidden="true" (click)="limparGrupo()"></i>
                </div>
              </div>
              <p-table [value]="servicos"  (sortFunction)="customSort($event)" [customSort]="true" [paginator]="paginarServico" [rows]="rows"
                       [rows]="10" [rowsPerPageOptions]="[5,10,25,50,100]">
                <ng-template pTemplate="header">
                  <tr class="row">
                    <th class="col-2" pSortableColumn="codigoGrupo" >Grupo <p-sortIcon field="codigoGrupo"></p-sortIcon></th>
                    <th class="col-2" pSortableColumn="codigoServico" >Código <p-sortIcon field="codigoServico"></p-sortIcon></th>
                    <th class="col-6" pSortableColumn="descricaoServicoAcentuado" >Descrição <p-sortIcon field="descricaoServicoAcentuado"></p-sortIcon></th>
                    <th class="col-2" pSortableColumn="statusServico">  Ações <p-sortIcon field="statusServico"></p-sortIcon></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-servicos >
                  <tr class="row">
                    <td class="col-2">{{servicos.codigoGrupo}}   </td>
                    <td class="col-2">{{servicos.codigoServico}}  </td>
                    <td class="col-6">{{ servicos.descricaoServicoAcentuado }}</td>
                    <td class="col-2">
                      <button type="button" *ngIf="servicos.statusServico == true && servicos.suspenso == false" class="br-button secondary  small" aria-label="Selecionar"
                              (click)="detalheServico(servicos)"  >
                        Selecionar
                      </button>

<!--                  JMD - 06/12/2021 -->
                      <button *ngIf="servicos.suspenso == true && servicos.suspenso == true" type="button" class="br-button  secondary  small" aria-label="Suspenso"
                              (click)="detalheServico(servicos)"  disabled>
                        Suspenso
                      </button>

                      <button type="button" *ngIf="servicos.statusServico == false" class="br-button secondary  small" aria-label="Inativo"
                      (click)="detalheServico(servicos)"  disabled>
                Inativo
              </button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            </div><!--</ng-template></p-splitter> -->
        </p-tabPanel>
        <!----- fim do painel do serviçco ----->
        <!-----inicio painel do material ----->
        <p-tabPanel header="Materiais (pdm) ({{ materiais?.length}})" *ngIf="materiais?.length > 0"  >
          <div class="row" *ngIf="materiais?.length > 0"><!-- <p-splitter  [panelSizes]="[30,70]" ><ng-template pTemplate>-->
              <div class="col-3 pesquisa-resultado" style="margin-left: -4px; margin-right: 4px;">
                <div><h6 class="mb-1">Filtros</h6></div>
                <div><h6 class="text-uppercase mb-2" style="font-size: 12px;"> Classes de  Materiais</h6></div>
                <hr>
                <div class="pt-3 pb-3">
                  <p-listbox [listStyle]="{'max-height':'550px','max-width':'250px'}"  [options]="classesMaterial" [(ngModel)]="selectedClasse" optionLabel="name"
                             (onClick)="classeEscolhida($event)">
                    <ng-template let-itens pTemplate="item">
                      <div *ngIf="itens.name == 'TODAS AS CLASSES'"><STRONG>{{itens.name}}</STRONG></div>
                      <div *ngIf="itens.name != 'TODAS AS CLASSES'">{{itens.name}}</div>
                    </ng-template>
                  </p-listbox>
                </div>
              </div><!-- </ng-template> --><!--  <ng-template pTemplate class="card"> -->
            <div class="col-9 pesquisa-resultado" style="margin-left: 4px; margin-right: -4px;">
              <div style="display: flex; flex-direction: row; align-items: center;" *ngIf="materiais?.length > 0">
                <h6 style="font-size: 14px;">Foram encontrados {{ materiais?.length }} resultados para os filtros </h6><!--<div class="br-tag interaction-select selected"><label for="tag01"><span>{{ texto }}</span></label></div><div class="br-tag interaction-select selected"><label for="tag01"><span>{{ selectedClasse.name }}</span></label></div>-->
              </div>
              <p-table [value]="materiais" [paginator]="paginarMaterial" [rows]="rows"  (sortFunction)="customSort($event)" [customSort]="true"
                       [rows]="10" [rowsPerPageOptions]="[5,10,25,50,100]">
                >
                <ng-template pTemplate="header">
                  <tr>
                    <th class="col-2" pSortableColumn="codigoClasse" >Classe<p-sortIcon field="codigoClasse"></p-sortIcon></th>
                    <th class="col-2" pSortableColumn="codigoPdm" >Pdm <p-sortIcon field="codigoPdm"></p-sortIcon></th>
                    <th class="col-6" pSortableColumn="nomePdm" >Padrão Descritivo de Material<p-sortIcon field="nomePdm"></p-sortIcon></th>
                    <th class="col-2" pSortableColumn="statusPDM" >  Ações <p-sortIcon field="statusPDM"></p-sortIcon></th>
                </ng-template>
                <ng-template pTemplate="body" let-materiais >
                  <tr>
                    <td class="col-2">{{materiais.codigoClasse}}</td>
                    <td class="col-2">{{materiais.codigoPdm}}  </td>
                    <td class="col-6">{{ materiais.nomePdm }}</td>
                    <td class="col-2" style="text-align: center;">
                      <button *ngIf="materiais.statusPDM == true" type="button" class="br-button  secondary  small" aria-label="Adicionar"
                              (click)="detalheMaterial(materiais.codigoPdm, materiais.nomePdm, materiais.codigoClasse)"  >
                        Selecionar
                      </button>
                      <button *ngIf="materiais.statusPDM == false" type="button" class="br-button  secondary  small" aria-label="Inativo"
                         (click)="detalheMaterial(materiais.codigoPdm, materiais.nomePdm, materiais.codigoClasse)"  disabled>
                        Inativo
                      </button>
                    </td>
                  </tr>
               </ng-template>
              </p-table>
            </div><!--</ng-template> </p-splitter>-->
          </div>
        </p-tabPanel>
        <!----- fim painel do material ------>
      </p-tabView>
    </div>
  </div>
  <!----------fim  novo container------------------ -->
</div>

<app-detalhe-material-siasgnet [material]="material" (aoVoltar)="voltar($event)" *ngIf="detalheMaterialVisivel"></app-detalhe-material-siasgnet>
<app-detalhe-servico-siasgnet [servico]="servico" (aoVoltar)="voltar($event)" *ngIf="detalheServicolVisivel"></app-detalhe-servico-siasgnet>

<div *ngIf="telaVazia">
  <div class="container-lg" *ngIf="!servicos?.length && !materiais?.length" >
      <div class="row pesquisa-resultado d-flex justify-content-center ">
        <div class="col-9">
          <div style="display: flex; flex-direction: column; align-items: center;">
            <img class="imagem " src="assets/imagem/empty.svg" alt="Imagem de Busca"/>
            <!--Utilize o campo de busca para pesquisar materiais ou serviços e visualizar os resultados.-->
          </div>
        </div>
      </div>
  </div>
</div>


<div class="container-lg" *ngIf="exibirMenssagemVazia">
  <div class="col-12 card" >
    <div class="justify-content-center">
      <div class="row" >
        <div class="col-12 ">
          <div style="display: flex; flex-direction: column; align-items: center;">
            <img class="imagem align-content-center" src="assets/imagem/empty.svg" width="250" alt="Imagem de Busca"/>
            <h4><p>Não foram encontrados materiais ou serviços com o critério informado!</p></h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #template1>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Você digitou um código de item que tem material e serviço associado a ele</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef1.hide()">
      <span aria-hidden="true">&times;</span></button>

  </div>
  <div class="modal-body">
    <p>Deseja seguir para material ou serviço?</p>
  </div>
  <div class="modal-footer ">
    <button type="button" class="br-button primary" (click)="seguirParaMaterial()">Material</button>
    <button type="button" class="br-button primary"(click)="seguirParaServico()">Serviço</button>
  </div>
</ng-template>
