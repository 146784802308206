import { BuscaService } from './../../../service/busca-service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationComponent } from '@serpro/ngx-dsgovbr';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NONE_TYPE } from '@angular/compiler';
@Component({
  selector: 'app-criar-servico',
  templateUrl: './criar-servico.component.html',
  styleUrls: ['./criar-servico.component.css'],
  providers: [ConfirmationService]
})

export class CriarServicoComponent implements OnInit {

  formulario: FormGroup;
  checado: boolean;
  tipo: any = ['1 - Serviços de Obras e Engenharia', '2 - Outros Serviços'];
  unidadeMedida: any;
  agrupamento: any;
  palavraChave: any;
  unidadeMedidaArray: any = [];
  agrupamentoArray: any = [];
  naturezaDespesaArray: any = [];
  aliasArray: any = [];
  palavraChaveArray: any = [];
  valorCpc: any;
  nomeCpc: string = "";
  agrupamentoSelecionado: any;
  resp: any;
  novoServico: any;
  expandCollapsed = false;
  agrupamentoBack: any;
  listaNaturezaDespesa: any = [];
  listaAlias: any = [];
  codigoServico: any = 0;
  modalRef1: BsModalRef;
  numMaxAlias = 25;
  numMaxDescricao = 750;
  numMaxNome = 120;
  visible: boolean = false;
  @ViewChild('ServiceSucess', {static: false} ) ServiceSucess: any;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private buscaService: BuscaService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

    zerarTudo(){
      this.formulario.reset();
      this.listaNaturezaDespesa = [];
      this.listaAlias = [];
      this.valorCpc = null;
      this.nomeCpc = "";
      this.aliasArray = [];
      this.unidadeMedidaArray = [];
      this.naturezaDespesaArray = [];
      this.formulario.patchValue({exclusivo_central: false, aplica_margemp: false});
    }

  ngOnInit(): void {
    this.buscaService
      .obterUnidadeDeMedidaServico()
      .subscribe((resp) => (this.unidadeMedida = resp));
    this.buscaService
      .obterAgrupamentoServico()
      .subscribe((resp) => (this.agrupamento = resp));

    this.formulario = this.formBuilder.group({
      nome: [null],
      codigoNbs: [""],
      descricaoNbs: [""],
      detalhes: [null],
      cpc: [{
        value: null
      }],
      dados_complementares: [null],
      suspenso: [false],
      ativo: [true],
      aplica_margemp: [false],
      exclusivo_central: [false],
      tipo: [null],
      alias: [null],
      notas: [null],
      unidade_medida: [null],
      natureza_despesa: [null],
      agrupamento: [null],
      aliasTela: [null],
      naturezaTela: [null],
      unidadeTela: [null],
      agrupamentoTela: [null]
    });
  }

onChange(e: any){
 this.agrupamentoSelecionado = e.target.value;
}

validaCampos() {
      if (this.valorCpc == null) {
         this.messageService.add({
         severity: 'error',
         summary: 'CPC é um campo obrigatório'
         });
         return;
      }
      if (this.formulario.controls.codigoNbs.status == 'INVALID') {
          this.messageService.add({
          severity: 'error',
          summary: 'Caso informado, o código NBS deve ter entre 3 e 9 caracteres'
          });
          return;
      }
      if (this.formulario.controls.nome.status == 'INVALID') {
         this.messageService.add({
         severity: 'error',
         summary: 'Nome é um campo obrigatório'
         });
         return;
      }
      if (this.formulario.value.tipo == null) {
         this.messageService.add({
         severity: 'error',
         summary: 'Tipo é um campo obrigatório',
         });
         return;
      }
      if (this.naturezaDespesaArray.length == 0) {
         this.messageService.add({
         severity: 'error',
         summary: 'Natureza da despesa é um campo obrigatório',
         });
         return;
      }
      if (this.unidadeMedidaArray.length == 0) {
        this.messageService.add({
        severity: 'error',
        summary: 'Unidade de Medida é um campo obrigatório',
        });
        return;
     }
      for (let i=0; i<=this.naturezaDespesaArray.length-1;i++){
        let nd = this.naturezaDespesaArray[i].substring(0,4);
          if ((nd < 3390)           ||
            (nd > 3399 && nd < 4490) ||
            (nd > 4499 && nd < 5590) ||
            (nd > 5599)) {
            this.messageService.add({
            severity: 'error',
            summary: 'Natureza da despesa inexistente',
            });
            return;
          }
      }
      this.onSubmitServico();
  }

  expandeOculta(){
     if(this.expandCollapsed){
        this.expandCollapsed = false;
     } else {
        this.expandCollapsed = true;
     }
  }

  onSubmitServico() {
    let listaUnidadeMedida = [];
    let listaNaturezaDespesa = [];
    for (const i of this.unidadeMedidaArray){
       listaUnidadeMedida.push(i.siglaUnidadeMedida);
    }

    for(let i of this.naturezaDespesaArray){
      let x = i.split("/",2);
      listaNaturezaDespesa.push(x[0]+x[1]);
    }


    this.formulario.value.unidade_medida = listaUnidadeMedida;
    this.formulario.value.natureza_despesa = listaNaturezaDespesa;
    this.formulario.value.agrupamento = this.agrupamentoSelecionado;
    this.formulario.value.alias = this.aliasArray;

    this.visible = true;
    this.buscaService.incluirNovoServico({
        codigoAgrupamentoServico: this.formulario.value.agrupamento,
        codigoCpc: this.valorCpc,
        codigoNbs: this.formulario.value.codigoNbs?.trim().replace(/\./g, ''),
        descricaoNbs: this.formulario.value.descricaoNbs?.trim(),
        descricaoServico: this.formulario.value.detalhes?.trim(),
        descricaoServicoAcentuado: this.formulario.value.nome?.trim(),
        nomeServico: this.formulario.value.nome?.trim(),
        servicoExclusivoCentralCompras: this.formulario.value.exclusivo_central,
        aplicaMargemPreferencia: this.formulario.value.aplica_margemp,
        //servicoSuspenso: this.formulario.value.suspenso,
        servicoSuspenso: false,
        statusServico: true,
        tipoServico: this.formulario.value.tipo.substr(0,1),
        listaUnidadeMedida: this.formulario.value.unidade_medida,
        listaNaturezaDespesa: this.formulario.value.natureza_despesa,
        listaAlias: this.formulario.value.alias

      }).subscribe(
      resp => {
        this.visible = false;
        if (resp){
          this.codigoServico = resp;
          this.ServiceSucess.show();
        }
      }, erro => {
        this.visible = false;
        if(typeof(erro.error) == "string"){
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao salvar os dados no servidor: ' + erro.error
            });
        }else{
          this.messageService.add({
            severity: 'error',
            summary: 'Não foi possível se conectar com o serviço neste momento, por favor tente mais tarde.'
            });
        }
      });
  }

  voltarTelaInicial() {
    this.router.navigate(['/buscaservico']);
  }

  selectCPC(cpc: any): void {
    this.valorCpc = cpc.codigoCpc;
    this.nomeCpc = cpc.nomeCpc;
    this.formulario.value.cpc = cpc.codigoCpc;
  }

  adicionaListUnidade(evento: any) {
    let aux = 0;
    for (let i of this.unidadeMedidaArray) {
      if (evento == i.siglaUnidadeMedida) {
        aux = 1;
      }
    }
    if (aux == 0) {
      for (let unidade of this.unidadeMedida) {
        if (unidade.siglaUnidadeMedida == evento) {
          this.unidadeMedidaArray.push({
            nomeUnidadeMedida: unidade.nomeUnidadeMedida,
            siglaUnidadeMedida: unidade.siglaUnidadeMedida,
          });
          this.formulario.patchValue({unidadeTela: null});
        }
      }
    } else{
      this.messageService.add({
        severity: 'warn',
        summary: 'Unidade de medida já cadastrada'
        });
    }
  }

  adicionaListAgrupamento(evento: any) {
    let aux = 0;
    for (let i of this.agrupamentoArray) {
      if (evento == i.codigoAgrupamentoServico) {
        aux = 1;
      }
    }
    if (aux == 0) {
      for (let agrupamento of this.agrupamento) {
        if (agrupamento.codigoAgrupamentoServico == evento) {
          this.agrupamentoArray.push({
            codigoAgrupamentoServico: agrupamento.codigoAgrupamentoServico,
            nomeAgrupamentoServico: agrupamento.nomeAgrupamentoServico,
          });
        }
      }
    }
  }


  adicionaListNatureza(valor: string){
    let aux = 0;
    let aux2 = Number(valor.substring(0,4));
    let aux3 = valor.length;
    valor = valor.trim();
    if(valor != "" &&  (aux3 == 9)){
      if((aux2 >= 3390 && aux2 <= 3399) ||
         (aux2 >= 4490 && aux2 <= 4499) ||
         (aux2 >= 5590 && aux2 <= 5599)){
        for( let i of this.naturezaDespesaArray){
          if(valor == i){
            aux = 1;
          }
        }
        if(aux == 0){
          this.naturezaDespesaArray.push(valor);
          this.formulario.patchValue({naturezaTela: null});
        } else{
          this.messageService.add({
            severity: 'warn',
            summary: 'Natureza da despesa já cadastrada'
            });
        }
      }
      else{
        this.messageService.add({
          severity: 'error',
          summary: 'Natureza da despesa inválida'
          });
      }
    }
    else{
      this.messageService.add({
        severity: 'error',
        summary: 'Preencher corretamente o campo de natureza da despesa'
        });
    }
  }

  adicionaListAlias(evento: any) {
    evento = evento.trim();
    let aux = 0;
    if(evento == ""){
      aux = 1;
    }
    for (let i of this.aliasArray) {
      if (evento == i) {
        aux = 1;
      }
    }
    if (aux == 0) {
      this.formulario.patchValue({aliasTela: null});
      this.aliasArray.push(evento);
    } else{
      this.messageService.add({
        severity: 'warn',
        summary: 'Alias/palavra-chave já cadastrada'
        });
    }
    this.numMaxAlias = 25;
  }

  adicionaListPalavraChave(evento: any) {
      let aux = 0;
      for (let i of this.palavraChaveArray) {
        if (evento == i) {
          aux = 1;
        }
      }
      if (aux == 0) this.palavraChaveArray.push(evento);
  }

  removeListAlias(evento: any){
    this.confirmationService.confirm({
      message: "Deseja excluir esse alias/palavra-chave?",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      key: evento,
      defaultFocus: "none",
      accept: () => {
        for (let index in this.aliasArray) {
          if (evento == this.aliasArray[index])
            this.aliasArray.splice(index, 1);
        }
      }
    });
  }

  removeListNatureza(evento: any){
    this.confirmationService.confirm({
      message: "Deseja excluir essa natureza da despesa?",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      key: evento,
      defaultFocus: "none",
      accept: () => {
        for (let index in this.naturezaDespesaArray) {
          if (evento == this.naturezaDespesaArray[index])
            this.naturezaDespesaArray.splice(index, 1);
        }
      }
    });
  }

  removeListUnidadeMedida(evento: any){
    this.confirmationService.confirm({
      message: "Deseja excluir essa unidade de medida?",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      key: evento.nomeUnidadeMedida,
      defaultFocus: "none",
      accept: () => {
        for (let index in this.unidadeMedidaArray) {
          if (
            evento.nomeUnidadeMedida ==
            this.unidadeMedidaArray[index].nomeUnidadeMedida
          )
            this.unidadeMedidaArray.splice(index, 1);
        }
      }
    });
  }

  removeListPalavraChave(evento: any){
     for (let index in this.palavraChaveArray) {
       if (evento == this.palavraChaveArray[index])
         this.palavraChaveArray.splice(index, 1);
     }
  }

  pressEnterAlias(evento: any, parametroMetodo: any){
    let tamanhoPermitidoAlias = 25;
    if(evento.key == "Enter"){
      this.numMaxAlias = tamanhoPermitidoAlias;
      this.adicionaListAlias(parametroMetodo);
      return;
    }
    this.numMaxAlias = tamanhoPermitidoAlias - parametroMetodo.length;
  }

  pressEnterNatureza(evento: any, parametroMetodo: any){
    if(evento.key == "Enter"){
      this.adicionaListNatureza(parametroMetodo);
    }
  }

  pressEnterDescricao(evento: any, parametroMetodo: any){
      let tamanhoPermitidoDescricao = 750;
      this.numMaxDescricao = tamanhoPermitidoDescricao - parametroMetodo.trim().length;
    }

  pressEnterNome(evento: any, parametroMetodo: any){
      let tamanhoPermitidoNome = 120;
      this.numMaxNome = tamanhoPermitidoNome - parametroMetodo.trim().length;
    }

  trimTextOnBlur(controle: any) {
    controle.value = controle.value.trim();
  }

}

