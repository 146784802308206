<app-cabecalho-interno></app-cabecalho-interno>
<br /><br /><br />
<div class="container-lg">
  <div class="mb-4 mt-2" ><!-- cabeçalho -->
    <div class="row">
        <div class="col-8" id="cabecalho">
          <h2 class="mb-0" aria-label="Manter Material">
            <span class="mouseover">Catálogo - Manter serviços</span>
          </h2>
          <h6 style="font-weight: 400;" class="mb-0 mt-1"> Use esta área para editar um serviço</h6>
        </div>
        <div class="col-4 row text-right" style="align-content: end">
          <div class="col-6 card border-0">
            <br-confirmation  #BackAlert popupTitle="Deseja voltar a tela incial?" acceptLabel="Sim" rejectLabel="Não"
                              question="Pode haver dados que não foram salvos." (accept)="voltarTelaInicial()" (reject)="BackAlert.hide()">
            </br-confirmation>
            <button
              class="br-button is-secondary"
              type="button"
              (click)="openAlert()"
            >
              Voltar
            </button>
          </div>
          <div class="col-6 card border-0">
            <br-loading [visible]="visible" label="Salvando ..."></br-loading>
            <button class="br-button is-primary" type="button" (click)="confirmaSalvar() ">Salvar</button>
          </div>
        </div>
      </div>

    <br-confirmation  #confirmCont popupTitle="Confirme a alteração" acceptLabel="Sim" rejectLabel="Não"
                      question="Deseja realmente alterar este serviço?" (accept)="salvar()">
    </br-confirmation>
    <br-confirmation  #ServicoSucess popupTitle="Serviço alterado com sucesso!" acceptLabel="Permanecer" rejectLabel="Voltar"
                      question="Código do serviço: {{dadosBasicos.codigoServico}}" (accept)="permanecerPagina()" (reject)="voltarTelaInicial()">
    </br-confirmation>

  </div>
  <hr>

<!--  <div [formGroup]="formulario">-->

    <div class="mb-4 row" *ngIf="dadosBasicos" >
      <h5 class="col-3">
        Código do serviço:
        <span style="color: red">{{ servicoParams.id }}</span>
      </h5>

      <div class="col-9 row" style="margin-top: 28px;">

        <div class="col-4" style="justify-content: flex-end">
          <div class="br-switch">
            <input
              id="margemp"
              type="checkbox"
              [(ngModel)]="dadosBasicos.aplicaMargemPreferencia"
              (click)="Alterou()"
              [disabled]="this.dadosBasicos.codigoNbs==null || this.dadosBasicos.codigoNbs.length==0"
            />
            <label for="margemp">Aplica margem de preferência:</label>
          </div>
        </div>

        <div class="col-4" style="justify-content: flex-end">
          <div class="br-switch">
            <input
              id="exclusivo"
              type="checkbox"
              [(ngModel)]="dadosBasicos.servicoExclusivoCentralCompras"
              (click)="Alterou()"
            />
            <label for="exclusivo">Exclusivo central compras:</label>
          </div>
        </div>

        <div class="col-2" style="justify-content: flex-end">
          <div class="br-switch">
            <input
              id="suspenso"
              type="checkbox"
              [(ngModel)]="dadosBasicos.servicoSuspenso"
              (click)="Alterou()"
            />
            <label for="suspenso">Suspenso:</label>
          </div>
        </div>

        <div class="col-2" style="justify-content: flex-end">
          <div class="br-switch">
            <input
              id="ativo"
              type="checkbox"
              [(ngModel)]="dadosBasicos.statusServico"
              (click)="Alterou()"
            />
            <label for="ativo">Ativo:</label>
          </div>
        </div>
      </div>
    </div>

    <br-fieldset
      *ngIf="dadosBasicos"
      [collapsible]="true"
      fieldsetTitle="Dados base"
      [collapsed]="expandCollapsed"
    >
      <div class="br-input col-12 mb-2" style="padding-bottom: 10px;">
        <label>Nome</label><label class="asterisco">*</label>
        <label class="texto-direita">Caracteres restantes: <span>{{numMaxServico}}</span></label>
        <input
          #nome
          class="p-inputtext"
          type="text"
          name="Nome"
          maxlength="120"
          [(ngModel)]="dadosBasicos.descricaoServicoAcentuado"
          (keyup)="pressEnterNome($event)"
          [disabled]="!dadosBasicos.statusServico"
        />
      </div>

      <div class="row col-12" style="padding-left: 0px; padding-bottom: 20px;">
        <div class="col-6" style="padding-top: 5px">
          <label>Agrupamento</label>
          <select class="custom-select select-input"
          [(ngModel)]="dadosBasicos.codigoAgrupamentoServico"
          [disabled]="!dadosBasicos.statusServico"
          (click)="Alterou()">
            <option value=""></option>
            <option *ngFor="let item of agrupamento"
                 [value]="item.codigoAgrupamentoServico">{{ item.nomeAgrupamentoServico }}
            </option>
          </select>
        </div>

        <div class="col-6">
          <label>Tipo</label><label class="asterisco">*</label>
          <select class="custom-select select-input" required
            [(ngModel)]="dadosBasicos.tipoServico"
            [disabled]="!dadosBasicos.statusServico"
            (click)="Alterou()">
            <option *ngFor="let item of tipo" [value]="item.codigoTipo">{{ item.nomeTipo }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="br-input col-10 mb-2" style="padding-bottom: 10px;">
          <label> CPC: {{dadosBasicos.codigoCpc}}</label>
          <input
            class="p-inputtext"
            type="text"
            name="CPC"
            [(ngModel)]="dadosBasicos.nomeCpc"
            [disabled]="!dadosBasicos.statusServico"
            readonly
          />
        </div>
        <div class="col-2 mt-4" style="padding-top: 15px">
          <button class="br-button secondary" type="button"
                  data-toggle="modal" data-target="#templateSelecionarCpc"
                  [disabled]="!dadosBasicos.statusServico"
                  (click)="Alterou()">
            Buscar CPC
          </button>
        </div>
      </div>

      <div class="row">

        <div class="col-4 br-input">
          <label>Código NBS</label>
          <p-inputMask mask="1.9999?.99.99"
            [(ngModel)]="dadosBasicos.codigoNbs"
            placeholder="Código NBS do serviço"
            (keyup)="Alterou()"
            (ngModelChange)="(this.dadosBasicos.codigoNbs==null || this.dadosBasicos.codigoNbs.length==0) ? this.dadosBasicos.aplicaMargemPreferencia=0 : null"
          ></p-inputMask>
        </div>

        <div class="col-8 br-input">
          <label>Descrição NBS</label>
          <input (keyup)="Alterou()" type="text" class="p-inputtext" [(ngModel)]="dadosBasicos.descricaoNbs" placeholder="Descrição NBS do serviço" maxlength="500"/>
        </div>

      </div>

      <div class="br-textarea large col-12">
        <label  style="padding-bottom: 10px;">Descrição</label>
        <label class="texto-direita">Caracteres restantes: <span>{{numMaxDescricao}}</span></label>
        <textarea class="Area" rows="5" cols="2" maxlength="750"
                  [(ngModel)]="dadosBasicos.descricaoServico"
                  placeholder="Sem descrição do serviço"
                  (keyup)="pressEnterDescricao($event)"
                  [disabled]="!dadosBasicos.statusServico"></textarea>
      </div>
    </br-fieldset>

    <br-second-tab-set *ngIf="dadosBasicos">
      <br-second-tab
        name="Alias / Palavra-chave"
        [active]="true"
        (activeChange)="carregaArray('alias')"
      >
      <div class="row mt-4 mb-4">
        <div class="br-input col-sm-10">
          <label> Alias/ Palavra-chave:</label>
          <label class="texto-direita">Caracteres restantes: <span>{{numMaxAlias}}</span></label>
          <input
            #Alias
            class="p-inputtext"
            type="text"
            placeholder="Digite uma palavra para adicionar"
            [(ngModel)]="alias"
            (keyup)="pressEnterAlias($event, Alias.value)"
            maxlength="25"
            [disabled]="!dadosBasicos.statusServico"
          />
        </div>
        <div class="col-2" style="align-self: flex-end; text-align: end">
          <button
            class="br-button secondary"
            type="button"
            (click)="adicionaListAlias()"
            [disabled]="!dadosBasicos.statusServico"
          >
            Adicionar
          </button>
        </div>
      </div>
      <div *ngFor="let alias of this.dadosBasicos.aliases; let i = index" class="row">
        <div class="col-2"></div>
        <label class="col-5" [ngClass]="dadosBasicos.statusServico  == false ? 'cnbs-disabled-text-color' : 'cnbs-enabled-text-color'">{{ alias.nomeAliasServico }}</label>
        <p-confirmDialog
          header="Confirmar exclusão"
          icon="far fa-question-circle"
          key="{{ alias.nomeAliasServico }}"
        ></p-confirmDialog>
        <button
          class="br-button col-sm-2"
          type="button"
          (click)="removeListAlias(alias.nomeAliasServico)"
          [disabled]="!dadosBasicos.statusServico"
        >
          Excluir
        </button>
      </div>
      </br-second-tab>

      <br-second-tab
        name="Natureza da despesa"
        (activeChange)="carregaArray('natureza')"
        >
        <div class="row mb-4 mt-4">
          <div class="br-input col-sm-10">
            <label>Natureza da despesa:</label>
            <p-inputMask
              mask="999999/99"
              placeholder="Digite uma natureza da despesa para adicionar"
              [(ngModel)]="naturezaDespesa"
              (keyup)="pressEnterNatureza($event)"
              [disabled]="!dadosBasicos.statusServico"
            ></p-inputMask>
          </div>
          <div class="col-2" style="align-self: flex-end; text-align: end">
            <button
              class="br-button secondary"
              type="button"
              (click)="adicionaListNatureza()"
              [disabled]="!dadosBasicos.statusServico"
            >
              Adicionar
            </button>
          </div>
        </div>
        <p-table
          class="col-6"
          [value]="dadosBasicos.naturezas"
          responsiveLayout="scroll"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="col-3"></th>
              <th class="col-4">Natureza da despesa</th>
              <th class="col-3">Ativo</th>
              <th class="col-1"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td class="col-3"></td>
              <td class="col-4">
              <span >
                {{ item.codigoClassificacaoContabilMascarado }}</span>
              </td>
              <td class="col-3">
                <br-switch
                  (click)="Alterou()"
                  [(ngModel)]="item.statusClassificacaoContabil"
                  [disabled]="!dadosBasicos.statusServico"
                ></br-switch>
              </td>
              <td class="col-1"></td>
            </tr>
          </ng-template>
        </p-table>
      </br-second-tab>

      <br-second-tab
        name="Unidades de medida"
        [active]="false"
      >
        <br-fieldset [collapsible]="true" fieldsetTitle="UNIDADES DE MEDIDA" [collapsed]="expandCollapsed">
          <div>
            <label>Unidades de medida</label>
          </div>
          <div class="row" style="margin-bottom: 15px; margin-left: 5px;">
            <select class="custom-select select-input col-sm-10" #unidadeMedidaServico
                    [disabled]="!dadosBasicos.statusServico">
              <option value=""></option>
              <option *ngFor="let unidade of unidadeMedida" [value]="unidade.siglaUnidadeMedida" [disabled]="!dadosBasicos.statusServico">
                {{ unidade.nomeUnidadeMedida }}
              </option>
            </select>
            <button class="br-button mx-auto secondary"
                    type="button" (click)="adicionaListUnidade(unidadeMedidaServico.value)"
                    [disabled]="!dadosBasicos.statusServico">
              Adicionar
            </button>
          </div>
          <br>
          <table>
            <tr>
              <th class="col-2"></th>
              <th class="col-5">Unidade de Medida</th>
              <th class="col-5">Ativo</th>
            </tr>
          </table>
          <br>
          <div *ngFor="let unidades of dadosBasicos.unidadesDeMedidas" class="row">
            <table>
              <tr>
                <td class="col-2"></td>
                <td class="col-5"><label>{{ unidades.nomeUnidadeMedida }}</label></td>
                <td class="col-5">
                  <br-switch
                    (click)="Alterou()"
                    [(ngModel)]="unidades.statusUnidadeMedida"
                    [disabled]="!dadosBasicos.statusServico">
                  </br-switch>
                </td>
              </tr>
            </table>
          </div>
        </br-fieldset>
      </br-second-tab>
    </br-second-tab-set>
    <div style="margin-bottom: 100px;">
    <div>
      <app-selecionar-cpc (cpcSelecionadoEvent)="selectCPC($event)"></app-selecionar-cpc>
    </div>

<!--  </div>-->

  </div>
</div>
