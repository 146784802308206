import {Component, OnInit, ViewChild} from '@angular/core';
import {Service} from '../../service/service';
import {ActivatedRoute, Router} from '@angular/router';
import {Classe} from '../model/classe.model';

import {Item} from '../../model/item.model';
import {Grupo} from '../model/grupo.model';
import {MessageService} from 'primeng/api';
import {AutoComplete} from 'primeng/autocomplete';
import {DetalheMaterialComponent} from '../detalhe-material/detalhe-material.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/security/services/auth-service.service';
import { TipoUsuarioEnum } from 'src/app/security/model/tipo-usuario-enum';
import { PesquisaService } from 'src/app/arealogada/pedido/pedido-catalogador.service';
import { EnvironmentUtil } from 'src/environments/EnviromentUtil';

@Component({
  selector: 'app-busca',
  templateUrl: './busca.component.html',
  styleUrls: ['./busca.component.css']
})
export class BuscaComponent implements OnInit {
  
  token: any;
  usrProfile:any;
  usrRoles:{[id:string]:boolean};

  cnet_id: any;
  itens: Item[] = [];
  texto: any | string | number;
  textoBusca: any | string | number;
  servicos: any ;   servicosRetornados: any ;
  materiais: any  ; materiaisRetornados: any  ;
  tipoSelecionado: string;
  filtros: Filtro[];
  filtro1: Filtro;
  rows = 10;
  loading: any;
  isDisabled = true;
  currentItem: any;
  exibirMenssagemVazia = false;
  sortOrder: number;
  sortField: string;
  gruposServico: Grupo[] = new Array();
  selectedGrupos: Grupo[] = new Array();
  classesMaterial: Classe[] = new Array();
  selectedClasses: Classe[] = new Array();
  selectedGrupo: Grupo = new Grupo();
  selectedClasse: Classe = new Classe();
  painelSelecionadoServico: false ;
  painelSelecionadoMaterial: true ;
  indexpainel = 0 ;
  sortKey: any;
  paginarServico = true ;
  paginarMaterial = true ;
  results: any;
  text: any;
  display = false;
  telaTabView = false;
  telaMensagemVazia = true;
  detalheMaterialVisivel =  false;
  detalheServicolVisivel =  false;
  material: any;
  servico: any;

  itensCarrinho: Item[];
  modalRef1: BsModalRef;
  @ViewChild('hint', {static: true}) hint: AutoComplete;
  @ViewChild('template1', {static: false} ) template1: any;

  pesquisando: boolean = false;

  constructor( private service: Service, private router: Router, private messageService: MessageService,
               private modalService: BsModalService, private route: ActivatedRoute,
               private servicePesquisa: PesquisaService,
               private authService: AuthService) {
    this.filtros = [
      {name: 'Todos', code: 1},
      {name: 'Material', code: 2},
      {name: 'Serviço', code: 3}
    ];
    this.tipoSelecionado = '';
  }
  ngOnInit(): void {
    this.cnet_id = this.route.snapshot.queryParams['cnet-id'];
    if (this.cnet_id != null){
      console.log('cnet_id ' + this.cnet_id);
      this.router.navigate(['/arealogada'], {
        queryParams: {
          ['cnet-id']:  this.cnet_id
        }
      });
    }
    this.itensCarrinho = this.getItens();
    this.filtro1 = new Filtro();
    this.filtro1.code = 1;
    this.pesquisando = false;

    this.token = this.authService.token;
    if (this.token){
      this.usrProfile=this.authService.getUserProfile(this.token);
      this.usrRoles=this.authService.getUserRoles(this.token);
    }

  }

  isUsuarioAutenticado(): boolean {
    return this.authService.isAutenticadoAutorizado(TipoUsuarioEnum.GOVERNO, []) && this.authService.isRefreshTokenValido()
  }

  pesquisarLupa(): void{
  this.pesquisando = true;
     this.texto = this.textoBusca;
     this.pesquisar();
     this.pesquisando = false;
  }
  async pesquisar(): Promise<any> {
    this.esconderTodos();
    this.resetArrays();
    this.selectedGrupo = new Grupo();
    this.selectedClasse = new Classe();

    if (!isNaN(this.texto)) {
      // await this.obterMateriaServicoPorCodigoNovo(); // em desenvolvimento
      const quantidadeServicoCodigo = await this.existeObterServicoPorCodigo();
      const quantidadeMaterialCodigo = await this.existeObterMaterialPorCodigo();
      if (quantidadeMaterialCodigo + quantidadeServicoCodigo === 0){
        this.exibirMenssagemVazia = true;
        return ;
      }
      if (quantidadeMaterialCodigo + quantidadeServicoCodigo > 1){
        this.modalRef1 = this.modalService.show(this.template1); // falta fazer o dialogo
        return ;
      }
      if (quantidadeServicoCodigo  > 0){
        this.obterServicoPorCodigo();
      }
      if (quantidadeMaterialCodigo  > 0){
        this.obterMaterialPorCodigo();
      }

    } else {
      const quantidadeServico = await this.obterServicosPorPalavra();
      const quantidadeMaterial = await this.obterPDMPorPalavra();
      const totalRegistros =  quantidadeServico + quantidadeMaterial;
      if (totalRegistros !== 0){
        this.telaMensagemVazia = false;
        this.telaTabView = true;
        this.exibirMenssagemVazia = false;
      }else{
        this.exibirMenssagemVazia = true;
      }
    }
  }
  seguirParaMaterial(): void {
    this.obterMaterialPorCodigo();
    this.modalRef1.hide();
  }

  seguirParaServico(): void {
    this.obterServicoPorCodigo();
    this.modalRef1.hide();
  }

 async  pesquisarEnter(event: any): Promise<any>{
   const text = event.target.value;
   this.texto = text;
   if (text.length >= 2) {
   this.pesquisando = true;
     setTimeout(() => this.hint.hide(), 200);
     await this.pesquisar();
     // this.texto = '';
     // this.textoBusca = '';
     this.pesquisando = false;
   }
 }

 atualizarLista(): void{
  this.itensCarrinho = this.getItens();
 }

  private carregarGrupoServico( servico: any ): void {
    if (!this.gruposServico.some(e => e.id === servico.codigoGrupo)) { // se o grupo na contem o código
      let grupo;
      grupo = new Grupo();
      grupo.id = servico.codigoGrupo;
      grupo.descricao = servico.nomeGrupo;
      grupo.code = servico.codigoGrupo;
      grupo.name = servico.codigoGrupo + ' - ' + servico.nomeGrupo;
      this.gruposServico.push(grupo);
    }
  }
  private carregarClasseMaterial( material: any ): void {
   // console.log(material.codigoClasse);
    if (!this.classesMaterial.some(e => e.id === material.codigoClasse)) { // se o grupo na contem o código
      let classe;
      classe = new Classe();
      classe.id = material.codigoClasse;
      classe.descricao = material.nomeClasse;
      classe.code = material.codigoClasse;
      classe.name = material.codigoClasse + '-' + material.nomeClasse ;
      this.classesMaterial.push(classe);
    }
  }

  detalheServico(servico: any): void {

    this.service.obterServicoPorCodigo(servico.codigoServico).subscribe(servicoResp => {
      this.servico = {
        id: servicoResp.codigoServico,
        nome: servicoResp.descricaoServicoAcentuado,
        codigoNbs: servicoResp.codigoNbs,
        aplicamp: servicoResp.aplicaMargemPreferencia
      }

      this.telaTabView = false;
      this.detalheServicolVisivel = true;

    }, (error) => console.error(error));

  }

  limparGrupo(): void {
    this.indexpainel = 0;
    this.servicos = [];
    let servicotmp;

    const grupo = new Grupo();
    grupo.id = '0';
    grupo.descricao = 'todos os grupos';
    grupo.code = 0 ;
    grupo.name = 'TODOS OS GRUPOS';

    this.selectedGrupo = grupo;

    if (grupo.id === '0'){
      for (servicotmp of this.servicosRetornados){
        this.servicos.push(servicotmp);
      }
    }

  //  console.log(this.selectedGrupo);
  }

  iniciarNovoPedido(tipo:string) {
    // Inicia pedido quando não são encontrados resultados na busca
    // tipo: 'S' (Serviço) ou 'M' (Material)
    const detalhesPedido = {
      pdm: null,
      nomePdm: null,
      caracteristicas: [],
      tipoPedido: tipo
    }

    this.servicePesquisa.criarPedido(detalhesPedido).subscribe( (pedido)=>{
      let appBaseUrl = EnvironmentUtil.obterUrlAplicacao();
      window.open(appBaseUrl + `/pedido/${pedido.numeroPedido}`,'_blank');

    }, (error) =>{
        this.messageService.add({
        severity: 'error',
        summary: 'Erro ao criar pedido. Tente novamente.'
        });
    })
  }

  detalheMaterial(codigopdm: any, nomepdm: any, codigoClasse: any): void { //VERIFICAR
    this.material = {
      id: codigopdm,
      nome: nomepdm,
      classe: codigoClasse
    }
    // this.router.navigate(['/detalhematerial/']);
    this.telaTabView = false;
    this.telaMensagemVazia = false;
    this.detalheMaterialVisivel = true;

  }

  getItens(): Item[]{
    const saved = localStorage.getItem('itensbusca');
    if (saved != null){
      this.itens = JSON.parse(saved);
    }

    for (let i = 1; i <= this.itens.length; i++){
      this.itens[i - 1].sequencial = i;
    }

    return this.itens;
  }

  getItensAtualizado(): Item[] {
    const saved = localStorage.getItem('itensbusca');
    if (saved != null){
      this.itens = JSON.parse(saved);
    }

    return this.itens;
  }

  selecionados(): void {
    this.router.navigate(['/exibirselecionados']);
  }

  liberarPesquisa(event: any): void {
  //  console.log(event.target.value);
    const teste = event.target.value;
    if (teste.length >= 2){
      this.isDisabled = false;
    }else{
      this.isDisabled = true;
    }
  }
  async obterServicosPorPalavra(): Promise <any> {
    return new Promise((resolve, reject) => {
    this.service.obterServicosPorPalavra(this.texto).subscribe(servico => {
      if (servico) {
        const grupo = new Grupo();
        grupo.id = '0';
        grupo.descricao = 'todos os grupos';
        grupo.code = 0 ;
        grupo.name = 'TODOS OS GRUPOS';
        this.gruposServico.push(grupo);
        let tmp;
        for (tmp of servico) {
          this.servicos.push(tmp);
          this.servicosRetornados.push(tmp);
          this.carregarGrupoServico(tmp);
        }
      }
      this.servicos.sort((a: any, b: any) => {
        if (a.statusServico == true && b.statusServico == true){
          return 0;
        }
        if (a.statusServico == true && b.statusServico == false){
          return -1;
        }
        return 1;
      });
      if (this.servicos.length > 9){
        this.paginarServico = true;
      }else{
        this.paginarServico = false;
      }
      resolve(this.servicos.length);
    }, (error) => console.error(error));
    });
  }

  async obterPDMPorPalavra(): Promise <any> {
    console.log(this.texto);
    return new Promise((resolve, reject) => {
      this.service.obterPDMPorPalavra(this.texto).subscribe(material => {
        if (material) {
          const classe = new Classe();
          classe.id = '0';
          classe.descricao = 'todas as classes';
          classe.name = 'TODAS AS CLASSES';
          this.classesMaterial.push(classe);
          let tmp;
          for (tmp of material) {
            this.materiais.push(tmp);
            this.materiaisRetornados.push(tmp);
            this.carregarClasseMaterial(tmp);
          }
          this.materiais.sort((a: any, b: any) => {
            if (a.statusPDM == true && b.statusPDM == true) {
              return 0;
            }
            if (a.statusPDM == true && b.statusPDM == false) {
              return -1;
            }
            return 1;
          });
          if (this.materiais.length > 9) {
            this.paginarMaterial = true;
          } else {
            this.paginarMaterial = false;
          }
          resolve(this.materiais.length);
        }
      }, (error) => console.error(error));
    });
  }





  searchHint(event: any): void {
   // console.log(event.query);
    if ( event.query.length >= 2){
      this.service.obterPDMeServicoPorPalavra(event.query).subscribe(data => {
        const x = {
          codigo: '',
          tipo: 'Todos',
          nome: event.query
       };
        data.push(x);
        this.results = data;
      });
    }
  }

  grupoEscolhido(event: any): void {
   // console.log( 'grupoEscolhido' + JSON.stringify(event.value.id));
    this.indexpainel = 0;
    this.servicos = [];
    let servicotmp;
    // const servicotodos = new Servico();

    if (event.value.id === '0'){
      for (servicotmp of this.servicosRetornados){
        this.servicos.push(servicotmp);
      }
    }else{
      for (servicotmp of this.servicosRetornados){
        if (servicotmp.codigoGrupo === event.value.id){
          this.servicos.push(servicotmp);
        }
      }
    }
    this.servicos.sort((a: any, b: any) => {
      if (a.statusServico == true && b.statusServico == true){
        return 0;
      }
      if (a.statusServico == true && b.statusServico == false){
        return -1;
      }
      return 1;
    });
    if (this.servicos.length > 9){
      this.paginarServico = true;
    }else {
      this.paginarServico = false;
    }
  }

  classeEscolhida(event: any): void {
   // console.log(JSON.stringify(event.value.id));
    this.indexpainel = 1;
    this.materiais = [];
    let materialtmp;
    if (event.value.id === '0'){
      for (materialtmp of this.materiaisRetornados){
        this.materiais.push(materialtmp);
      }
    }else{
      for (materialtmp of this.materiaisRetornados){
        if (materialtmp.codigoClasse === event.value.id){
          this.materiais.push(materialtmp);
        }
      }
    }
    this.materiais.sort((a: any, b: any) => {
      if (a.statusPDM == true && b.statusPDM == true){
        return 0;
      }
      if (a.statusPDM == true && b.statusPDM == false){
        return -1;
      }
      return 1;
    });
    if (this.materiais.length > 9){
      this.paginarMaterial = true;
    }else {
      this.paginarMaterial = false;
    }
  }

  onSortChange(event: any): void {
    const value = event.target.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }
   customSort(event: any): void {
      event.data.sort((data1: any, data2: any) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        let result = null;

        if (event.field === 'id'){
         value1 = parseInt(value1);
         value2 = parseInt(value2);
       }
        if (value1 == null && value2 != null) {
          result = -1;
        }
       else if (value1 != null && value2 == null) {
          result = 1;
 }
       else if (value1 == null && value2 == null) {
          result = 0;
 }
       else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
 }
       else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
 }

        return (event.order * result);
      });
    }



  async buscaEscolhido(codigo: any, tipo: any, nome: any): Promise<any> {
   console.log('buscaEscolhido' + codigo + tipo + nome);
   this.texto = nome;
   this.esconderTodos();
   this.resetArrays();
   if (tipo === 'M'){ // material

      this.service.dadosBasicosPdmPorCodigo(codigo).subscribe(materialRetorno => {
          this.pesquisando = true;
          this.material = {
            id: materialRetorno.codigoPdm,
            nome: materialRetorno.nomePdm,
            classe: materialRetorno.codigoClasse
          }
          this.telaMensagemVazia = false;
          this.detalheMaterialVisivel = true;
          this.texto = '';
          this.pesquisando = false;
      }, (error) => console.error(error));

   }
   if (tipo  === 'S'){ // servico
     // console.log('serviço');
      this.service.obterServicoPorCodigo(codigo).subscribe(servico => {
        if (servico) {
        this.pesquisando = true;
          this.servicos = []; this.servicosRetornados = [];
          this.servicos.push(servico);
          this.servicosRetornados.push(servico);
          this.carregarGrupoServico(servico);
          this.texto = '';
          this.telaTabView = true;
          this.pesquisando = false;
        }
      }, (error) => console.error(error));
    }
   if (tipo  === 'Todos') { // servico
   this.pesquisando = true;
      await this.pesquisar();
      this.pesquisando = false;
   }
   // this.texto = '';
   // this.textoBusca = '';
  }
  voltar($event: any): void {// limpar exibir home
    this.telaTabView = true;
    this.detalheMaterialVisivel = false;
    this.detalheServicolVisivel = false;
  }

  esconderTodos(): void {
    this.telaTabView = false;
    this.detalheMaterialVisivel = false;
    this.detalheServicolVisivel = false;
    this.telaMensagemVazia = false;
    this.exibirMenssagemVazia = false;
  }

  resetArrays(): void{
    this.materiais = [];
    this.servicos = [];
    this.servicosRetornados = [];
    this.materiaisRetornados = [];
    this.gruposServico  = [];
    this.selectedGrupos = [] ;
    this.classesMaterial = [];
    this.selectedClasses = [];
    this.material = undefined;
    this.servico = undefined;
  }

  voltaTelaInicial(): void {
    this.resetArrays();
    this.esconderTodos();
    this.telaMensagemVazia = true;
    this.textoBusca ="";
  }

  // Todo: em desenvolvimento
  async obterServicoPorCodigo(): Promise <any> {
    console.log(this.texto);
    return new Promise((resolve, reject) => {
      this.service.obterServicoPorCodigo(this.texto).subscribe(servico => {
        if (servico) {
          this.servicos.push(servico);
          this.servicosRetornados.push(servico);
          this.carregarGrupoServico(servico);
          this.telaTabView = true;
        }
        resolve(this.servicos.length);
      }, (error) => console.error(error));
    });
  }
  async existeObterServicoPorCodigo(): Promise <any> {
    let quantidade = 0;
    return new Promise((resolve, reject) => {
      this.service.obterServicoPorCodigo(this.texto).subscribe(servico => {
        if (servico) {
          quantidade = 1;
        }
        resolve(quantidade);
      }, (error) => console.error(error));
    });
  }

  async obterMaterialPorCodigo(): Promise <any> {
    let quantidade = 0;
    return new Promise((resolve, reject) => {
      this.service.obterMaterialPorCodigo(this.texto).subscribe(materialRetorno => {
        if (materialRetorno) {
          this.material = {
            classe: materialRetorno.codigoClasse,
            codigo: materialRetorno.codigoItem
          }
          this.detalheMaterialVisivel = true;
          this.telaTabView = false;
          quantidade = 1 ;
        }
        resolve(quantidade);
      }, (error) => console.error(error));
    });
  }

  async existeObterMaterialPorCodigo(): Promise <any> {
    console.log(this.texto);
    let quantidade = 0;
    return new Promise((resolve, reject) => {
      this.service.obterMaterialPorCodigo(this.texto).subscribe(materialRetorno => {
        if (materialRetorno) {
          quantidade = 1 ;
        }
        resolve(quantidade);
      }, (error) => console.error(error));
    });
  }

  obterServicoseMaterialPorPalavra(): void {
    this.service.obterServicosPorPalavra(this.texto).subscribe(servico => {
      if (servico) {
        const grupo = new Grupo();
        grupo.id = '0';
        grupo.descricao = 'todos os grupos';
        grupo.code = 0 ;
        grupo.name = 'TODOS OS GRUPOS';
        this.gruposServico.push(grupo);
        let tmp;
        for (tmp of servico) {
          this.servicos.push(tmp);
          this.servicosRetornados.push(tmp);
          this.carregarGrupoServico(tmp);
        }
      }
      this.servicos.sort((a: any, b: any) => {
        if (a.statusServico == true && b.statusServico == true){
          return 0;
        }
        if (a.statusServico == true && b.statusServico == false){
          return -1;
        }
        return 1;
      });
      if (this.servicos.length > 9){
        this.paginarServico = true;
      }else{
        this.paginarServico = false;
      }
    }, (error) => console.error(error));

    this.service.obterPDMPorPalavra(this.texto).subscribe(material => {
      if (material) {
        const classe = new Classe();
        classe.id = '0';
        classe.descricao = 'todas as classes';
        classe.name = 'TODAS AS CLASSES';
        this.classesMaterial.push(classe);
        let tmp;
        for (tmp of material) {
          this.materiais.push(tmp);   this.materiaisRetornados.push(tmp);
          // console.log(JSON.stringify(tmp));
          this.carregarClasseMaterial(tmp);
        }
        this.materiais.sort((a: any, b: any) => {
          if (a.statusPDM == true && b.statusPDM == true){
            return 0;
          }
          if (a.statusPDM == true && b.statusPDM == false){
            return -1;
          }
          return 1;
        });
        if (this.materiais.length > 9){
          this.paginarMaterial = true;
        }else{
          this.paginarMaterial = false;
        }
      }
    }, (error) => console.error(error));
  }

  async buscaEscolhido2(codigo: any, tipo: any, nome: any): Promise<any> {
    console.log('<------ buscaEscolhido ------ >>>' + codigo + tipo + nome);
    const text = nome;
    this.texto = text;
    this.esconderTodos();
    this.resetArrays();
    this.selectedGrupo = new Grupo();
    this.selectedClasse = new Classe();
    const quantidadeServico = await this.obterServicosPorPalavra2(nome);
    const quantidadeMaterial = await this.obterPDMPorPalavra2(nome);
    const totalRegistros =  quantidadeServico + quantidadeMaterial;
    if (totalRegistros !== 0){
      this.telaMensagemVazia = false;
      this.telaTabView = true;
      this.exibirMenssagemVazia = false;
    }else{
      this.exibirMenssagemVazia = true;
    }
  }

  async obterServicosPorPalavra2(texto: any): Promise <any> {
    return new Promise((resolve, reject) => {
      this.service.obterServicosPorPalavra(texto).subscribe(servico => {
        if (servico) {
          const grupo = new Grupo();
          grupo.id = '0';
          grupo.descricao = 'todos os grupos';
          grupo.code = 0 ;
          grupo.name = 'TODOS OS GRUPOS';
          this.gruposServico.push(grupo);
          let tmp;
          for (tmp of servico) {
            this.servicos.push(tmp);
            this.servicosRetornados.push(tmp);
            this.carregarGrupoServico(tmp);
          }
        }
        this.servicos.sort((a: any, b: any) => {
          if (a.statusServico == true && b.statusServico == true){
            return 0;
          }
          if (a.statusServico == true && b.statusServico == false){
            return -1;
          }
          return 1;
        });
        if (this.servicos.length > 9){
          this.paginarServico = true;
        }else{
          this.paginarServico = false;
        }
        resolve(this.servicos.length);
      }, (error) => console.error(error));
    });
  }
  async obterPDMPorPalavra2(texto: any): Promise <any> {
    return new Promise((resolve, reject) => {
      this.service.obterPDMPorPalavra(texto).subscribe(material => {
        if (material) {
          const classe = new Classe();
          classe.id = '0';
          classe.descricao = 'todas as classes';
          classe.name = 'TODAS AS CLASSES';
          this.classesMaterial.push(classe);
          let tmp;
          for (tmp of material) {
            this.materiais.push(tmp);
            this.materiaisRetornados.push(tmp);
            this.carregarClasseMaterial(tmp);
          }
          this.materiais.sort((a: any, b: any) => {
            if (a.statusPDM == true && b.statusPDM == true) {
              return 0;
            }
            if (a.statusPDM == true && b.statusPDM == false) {
              return -1;
            }
            return 1;
          });
          if (this.materiais.length > 9) {
            this.paginarMaterial = true;
          } else {
            this.paginarMaterial = false;
          }
          resolve(this.materiais.length);
        }
      }, (error) => console.error(error));
    });
  }


}

class Filtro {
  code: number;
  name: string;
}


// referências
// https://stackoverflow.com/questions/8217419/how-to-determine-if-javascript-array-contains-an-object-with-an-attribute-that-e
// https://angular.io/guide/user-input
// https://getbootstrap.com/docs/5.0/layout/gutters/

