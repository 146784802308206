<app-cabecalho-interno></app-cabecalho-interno> <br /><br /><br />
<div class="container-lg">
  <div class="mb-4 mt-2">
    <!-- cabeçalho -->
    <div class="row">
      <div class="col-8" id="cabecalho">
        <h2 class="mb-0" aria-label="Manter Unidades de Administração de Serviços Gerais (UASGs)">
            <span class="mouseover">Catálogo - Unidades de Administração de Serviços Gerais (UASGs)</span>
        </h2>
        <h6 style="font-weight: 400" class="mb-0 mt-1">
        Use esta área para incluir/editar UASGs catalogadoras
        </h6>
      </div>

      <div class="col-4 text-right row" style="align-items: end">
        <div class="col-sm-6 card border-0">
          <button
            class="br-button is-secondary"
            type="button"
            (click)="voltarTelaInicial()"
          >
            Voltar
          </button>
        </div>
        <div class="col-sm-6 card border-0">
          <br-loading [visible]="visible" label="Salvando ..."></br-loading>
          <button
            class="br-button is-primary"
            type="button"
            data-toggle="modal"
            (click)="limpaCampos()"
            data-target="#incluirUasg"
          >
            Incluir
          </button>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <div>
    <p-table
      [value]="listaUasgs"
      [rows]="10"
      [paginator]="true"
      responsiveLayout="scroll"
      [rowHover]="true"
      dataKey="id"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} entradas"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
    >
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between row">
          <h5 class="ml-3 mt-0">Valores das Uasgs</h5>

          <div class="br-checkbox">  <!-- A FAZER - JMD -->
            <input id="somenteAtivos" name="somente-ativos" type="checkbox" aria-label="somente ativos" (click)="somenteAtivos()" [(ngModel)]="ativos"/>
            <label for="somenteAtivos">Mostrar somente UASGs catalogadoras</label>
          </div>

          <div class="br-input col-5 mb-3">
            <span class="mt-5"> </span>
            <input
              [(ngModel)]="filtroUasg"
              (keyup)="filtrarUasg()"
              class="p-inputtext"
              placeholder="Digite uma palavra para filtrar o resultado "
            />
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="codigoUasg">
            Código<p-sortIcon field="codigoUasg"></p-sortIcon>
          </th>
          <th pSortableColumn="nomeUasg">
            Nome <p-sortIcon field="nomeUasg"></p-sortIcon>
          </th>
          <th pSortableColumn="catalogador">
            Catalogadora <p-sortIcon field="catalogador"></p-sortIcon>
          </th>
          <th>Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-uasg>
        <tr>
          <td>
            <span style="color: black;" >{{ uasg.codigoUasg }}</span>
          </td>
          <td>
            <span  style="color: black;" >{{ uasg.nomeUasg }}</span>
            <!-- <span  style="color: #9f9a9a;" >{{ uasg.nomeUasg }}</span> -->
          </td>
          <td>
            <span style="color: black;" >{{ uasg.catalogador ? "Sim" : "Não" }}</span>
          </td>
          <td>
            <button
              class="br-button"
              data-toggle="modal"
              data-target="#visualizarUASG"
              aria-label="Botão de ação"
              pTooltip="Visualizar"
              (click)="pegarDados('visualiza', uasg)"
              tooltipPosition="top"
            >
              <i class="fas fa-eye" aria-hidden="true"></i>
            </button>
            <button
              class="br-button"
              data-toggle="modal"
              type="button"
              data-target="#editarUASG"
              pTooltip="Editar"
              (click)="pegarDados('edita', uasg)"
              tooltipPosition="top"
            >
              <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!--  MODAIS  -->

  <div
    id="incluirUasg"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Incluir UASG</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class="row">
            <div class="mt-3 col-md-6">
              <div class="row mt-3">
                <label class="col-5">Código da UASG<span class="red-star">*</span></label>
                <label *ngIf="erroCodigoUASG" class="col-7 pt-2" style="color: red;">
                  {{ erroCodigoUASG }}
                </label>
              </div>
              <div class="br-input">
                <input #incluirCodUASGCampo [(ngModel)]="incluirCodigoUASG" brNumbersOnly maxlength="6" (input)="validaCodigoUASG(incluirCodUASGCampo)"/>
              </div>
            </div>
            <div class="col-md-6 align-self-center" style="text-align: end" (click)="incluiCatalogadoraToggle()">
              <div
                class="br-switch medium switch-basic"
                style="margin-left: 10px"
              >
                <input type="checkbox" [(ngModel)]="incluirCatalogadora"/>
                <label for="incluirCatalogadora">Catalogadora</label>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <label class="col-3">Nome de Designação<span class="red-star">*</span></label>
            <label *ngIf="erroNomeDesignacao" class="col-9 pt-2" style="color: red;">
              {{ erroNomeDesignacao }}
            </label>
          </div>
          <div class="br-input mb-5">
            <input #incluirNomeDesig [(ngModel)]="incluirNomeDesignacao" maxlength="{{numMaxNomeDesignacao}}" (input)="validaNomeDesignacao(incluirNomeDesig)"/>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button
              class="br-button secondary"
              data-dismiss="modal"
              (click)="limpaCampos()"
            >
              Voltar
            </button>
          </div>
          <div>
            <button
              class="br-button primary"
              [disabled]="disableSave"
              (click)="salvarUASG()"
              data-dismiss="modal"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="editarUASG"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Editar UASG</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <br />
        <div class="container-lg">
          <div class="row">
            <div class="br-input mt-3 col-md-6">
              <label>Código da UASG</label>
              <input [(ngModel)]="editaCodigoUASG" readonly />
            </div>
            <div class="col-md-6 align-self-center" style="text-align: end" (click)="editaCatalogadoraToggle()">
              <div
                class="br-switch medium switch-basic"
                style="margin-left: 10px"
              >
                <input type="checkbox" [(ngModel)]="editaCatalogadora"/>
                <label for="editaCatalogadora">Catalogadora</label>
              </div>
            </div>
          </div>
          <div class="br-input mt-3">
            <label>Nome</label>
            <input [(ngModel)]="editaNome" readonly />
          </div>
          <div class="row mt-3">
            <label class="col-3">Nome de Designação<span class="red-star">*</span></label>
            <label *ngIf="erroNomeDesignacao" class="col-9 pt-2" style="color: red;">
              {{ erroNomeDesignacao }}
            </label>
          </div>
          <div class="br-input mb-5">
            <input #editarNomeDesig [(ngModel)]="editaNomeDesignacao" maxlength="{{numMaxNomeDesignacao}}" (input)="validaNomeDesignacaoEdicao(editarNomeDesig)"/>
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" data-dismiss="modal">
              Voltar
            </button>
          </div>
          <div>
            <button
              class="br-button primary"
              [disabled]="!this.editaNomeDesignacao || this.erroNomeDesignacao"
              (click)="atualizarUASG()"
              data-dismiss="modal"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="visualizarUASG"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Classe"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-media">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">UASG</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="container-lg">
          <div class="row">
            <div class="br-input mt-3 col-md-6">
              <label>Código da UASG</label>
              <input [(ngModel)]="visualizaCodigoUASG" readonly />
            </div>
            <div class="col-md-6 align-self-center" style="text-align: end">
              <div
                class="br-switch medium switch-basic"
                style="margin-left: 10px"
              >
                <input type="checkbox" [(ngModel)]="visualizaCatalogadora" readonly />
                <label for="visualizaCatalogadora">Catalogadora</label>
              </div>
            </div>
          </div>
          <div class="br-input mt-3">
            <label>Nome</label>
            <input [(ngModel)]="visualizaNome" readonly />
          </div>
          <div class="br-input mt-3 mb-5">
            <label>Nome de Designação</label>
            <input [(ngModel)]="visualizaNomeDesignacao" readonly />
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <button class="br-button secondary" data-dismiss="modal">
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #uasgSucessoCriar>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">UASG incluída com sucesso</h4>
        <button type="button" class="close" (click)="sairModalSalvar()">
          &times;
        </button>
      </div>
      <div class="container-lg">
        <div
          class="flex justify-content-center mb-5 mt-5"
          style="text-align: center"
        >
          <label>UASG incluída com sucesso.</label>
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <button class="br-button secondary" (click)="sairModalSalvar()">
            Voltar
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #uasgSucessoEditar>
    <div>
      <div class="modal-header">
        <h4 class="modal-title">UASG editada com sucesso</h4>
        <button type="button" class="close" (click)="sairModalEditar()">
          &times;
        </button>
      </div>
      <div class="container-lg">
        <div
          class="flex justify-content-center mb-5 mt-5"
          style="text-align: center"
        >
          <label>UASG editada com sucesso.</label>
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <button class="br-button secondary" (click)="sairModalEditar()">
            Voltar
          </button>
        </div>
      </div>
    </div>
  </ng-template>

</div>